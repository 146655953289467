.w-layout-grid {
	grid-row-gap: 16px;
	grid-column-gap: 16px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.body {
	color: #de9808;
	font-family: Minervamodern;
}

.search-button {
	width: 38px;
	height: 38px;
	background-color: #4f95d0;
	background-image: url('/20181016082423/assets/images/search_wht.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border-radius: 20px;
	margin-left: 0;
	margin-right: 0;
	transition: all 0.45s;
}

.search-button:hover {
	background-color: #2f6ca0;
}

.main-button {
	justify-content: flex-end;
	align-items: center;
	margin-top: 6px;
	margin-bottom: 6px;
	display: flex;
}

.location-link {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #fff;
	border-color: #fff rgba(33, 33, 33, 0.1);
	border-radius: 0;
	align-items: center;
	margin-right: 1rem;
	padding-right: 1rem;
	text-decoration: none;
	transition: background-color 0.4s;
	display: flex;
}

.location-link:hover {
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
	border-color: #000;
}

.wrapper {
	width: 100%;
	justify-content: flex-end;
	display: flex;
}

.contact-us {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #fff;
	border-color: #fff rgba(33, 33, 33, 0.1);
	border-radius: 0;
	align-items: center;
	margin-right: 1rem;
	padding-right: 1rem;
	text-decoration: none;
	transition: background-color 0.4s;
	display: flex;
}

.contact-us:hover {
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
	border-color: #000;
}

.top-nav {
	height: auto;
	justify-content: space-between;
	align-items: center;
	padding: 20px 100px;
	display: flex;
}

.search-bar {
	z-index: 15;
	width: 60%;
	height: 69%;
	background-color: #fff;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	justify-content: space-between;
	align-items: center;
	padding-left: 1rem;
	padding-right: 1rem;
	display: none;
	position: absolute;
	top: 95px;
	bottom: auto;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.uui-button-row {
	grid-column-gap: 0.75rem;
	grid-row-gap: 0.75rem;
	flex-wrap: wrap;
	align-items: center;
	display: flex;
}

.div-block-119 {
	width: 25px;
	height: 25px;
	cursor: pointer;
	background-image: url('/20181016082423/assets/images/exit-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
}

.uui-button-link-gray-2 {
	grid-column-gap: 0.5rem;
	color: #475467;
	white-space: nowrap;
	background-color: rgba(0, 0, 0, 0);
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-button-link-gray-2:hover {
	color: #344054;
}

.uui-button-link-gray-2.is-button-xsmall {
	font-size: 0.875rem;
}

.uui-navbar02_dropdown-content-left-2 {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	flex: 1;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	padding: 0.5rem 2rem 0 0;
	display: grid;
}

.nav-link {
	color: #053061;
	text-transform: uppercase;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Outfit, sans-serif;
	font-size: 17px;
	font-weight: 500;
	line-height: 24px;
	transition: all 0.3s;
	display: flex;
}

.nav-link:hover {
	color: #67011f;
}

.nav-link.w--current {
	color: #000;
}

.uui-navbar02_blog-item {
	border-radius: 0.5rem;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: max-content;
	grid-auto-columns: 1fr;
	padding: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar02_blog-item:hover {
	background-color: #fff;
}

.uui-button-link {
	grid-column-gap: 0.5rem;
	color: #6941c6;
	white-space: nowrap;
	background-color: rgba(0, 0, 0, 0);
	justify-content: center;
	align-items: center;
	padding: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-button-link:hover {
	color: #53389e;
}

.uui-button-link.is-button-xsmall {
	font-size: 0.875rem;
}

.search-bar-wrap {
	height: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
	padding-right: 15px;
	display: flex;
}

.uui-navbar02_blog-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.uui-navbar02_heading-2 {
	color: #1a1a1a;
	letter-spacing: normal;
	text-transform: capitalize;
	cursor: pointer;
	margin-top: 0;
	margin-bottom: 0.25rem;
	padding-left: 0.75rem;
	display: none;
}

.search-input-exit {
	width: 5%;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/exit-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 12px 12px;
	display: block;
}

.search-input-exit.two {
	z-index: 5;
	width: 20px;
	padding: 0 0;
	display: none;
	position: relative;
}

.search {
	justify-content: flex-end;
	align-items: center;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: none;
}

.uui-navbar02_dropdown-content-right-2 {
	max-width: 22rem;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex: 1;
	grid-template-rows: max-content auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	padding: 1.25rem;
	display: none;
	position: relative;
}

.uui-navbar02_dropdown-toggle-2 {
	color: #475467;
	align-items: center;
	padding: 1.4rem 0px 1.35rem 0px;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-weight: 600;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar02_dropdown-toggle-2:hover {
	color: #344054;
}

.uui-navbar02_dropdown-toggle-2.w--open {
	height: 100%;
	padding-bottom: 1.75rem;
}

.uui-navbar02_menu-2 {
	flex: 1;
	justify-content: flex-end;
	align-items: center;
	font-family: Minervamodern;
	display: flex;
	position: static;
}

.dropdown-link {
	grid-column-gap: 0rem;
	grid-row-gap: 0px;
	color: #1a1a1a;
	border-radius: 0.5rem;
	grid-template-rows: auto;
	grid-template-columns: max-content 1fr;
	grid-auto-columns: 1fr;
	justify-content: flex-start;
	align-items: center;
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	padding-left: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.dropdown-link:hover {
	background-color: rgba(0, 0, 0, 0);
}

.uui-navbar02_blog-image-wrapper {
	width: 100%;
	border-radius: 0.375rem;
	margin-bottom: 1.5rem;
	padding-top: 55%;
	position: relative;
	overflow: hidden;
}

.uui-navbar02_menu-dropdown {
	align-items: center;
	display: flex;
	position: static;
}

.text-block-12 {
	color: #053061;
	text-transform: uppercase;
	font-family: Outfit, sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}

.text-block-12:hover {
	color: #67011f;
}

.brand {
	background-color: #fff;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-left: 0;
	display: none;
}

.uui-navbar02_dropdown-right-overlay-absolute {
	min-width: 100vw;
	background-color: #f9fafb;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: auto;
}

.uui-navbar02_menu-left-2 {
	grid-column-gap: 20px;
	grid-row-gap: 32px;
	justify-content: flex-end;
	margin-top: 0;
	margin-right: 16px;
	display: flex;
}

.uui-navbar02_dropdown-content-wrapper {
	z-index: 1;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	display: grid;
	position: relative;
}

.search-input {
	width: 95%;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #000;
	margin-bottom: 0;
	padding: 1.8rem 3.1rem 1.8rem 1rem;
	font-size: 0.85rem;
	font-weight: 300;
	position: relative;
}

.login-button-main-mobile {
	height: auto;
	color: #fff;
	background-color: #007050;
	background-image: url('/20181016082423/assets/images/lock.svg');
	background-position: 9%;
	background-repeat: no-repeat;
	background-size: 20px;
	border: 2px #84d5f7;
	border-radius: 100px;
	align-items: center;
	padding: 9px 25px 9px 40px;
	font-family: Ubuntu, Helvetica, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: none;
}

.login-button-main-mobile:hover {
	background-color: #1a1a1a;
}

.uui-navbar02_item-heading-2 {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: capitalize;
	margin-bottom: 0.25rem;
	font-family: Outfit, sans-serif;
	font-weight: 400;
	overflow: visible;
}

.uui-navbar02_item-heading-2:hover {
	color: #5fa4ce;
	font-family: Outfit, sans-serif;
}

.uui-navbar02_item-right {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-weight: 400;
	display: flex;
}

.uui-navbar02_dropdown-link-list {
	grid-column-gap: 0px;
	grid-row-gap: 0rem;
	flex-direction: column;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-rows: max-content;
	grid-auto-columns: 1fr;
	display: flex;
}

.nav-items-container {
	width: 100%;
	height: 100%;
	max-width: none;
	justify-content: space-between;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.uui-navbar02_dropdown-content-2 {
	width: 100%;
	display: flex;
}

.uui-dropdown-icon {
	width: 1.25rem;
	height: 1.25rem;
	color: #053061;
	justify-content: center;
	align-items: center;
	margin-right: 1rem;
	display: flex;
	position: absolute;
	top: auto;
	bottom: auto;
	left: auto;
	right: 0%;
}

.uui-navbar02_menu-button-3 {
	padding: 0;
}

.uui-navbar02_dropdown-content-button-wrapper {
	letter-spacing: normal;
	margin-top: 0.75rem;
	font-size: 0.875rem;
}

.uui-text-size-small {
	color: #475467;
	letter-spacing: normal;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 0.875rem;
	line-height: 1.5;
}

.uui-navbar02_dropdown-list-2 {
	box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.uui-navbar02_dropdown-list-2.w--open {
	width: auto;
	max-width: none;
	min-width: auto;
	background-color: #053061;
	padding-left: 0%;
	padding-right: 0%;
	position: absolute;
	top: 5rem;
	bottom: auto;
	left: auto;
	right: auto;
	overflow: hidden;
}

.uui-navbar02_blog-content {
	flex-direction: column;
	justify-content: flex-start;
	font-weight: 400;
	display: flex;
}

.uui-navbar02_dropdown-blog-item-wrapper {
	flex-direction: column;
	grid-template-rows: auto auto auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-items: flex-start;
}

.search-icon {
	width: 35px;
	height: 35px;
	min-height: auto;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border: 0.25px rgba(0, 0, 0, 0);
	border-radius: 5px;
	padding: 0 0.25rem;
	transition: all 0.3s, background-color 0.3s;
}

.search-icon:hover {
	background-color: #00e5e0;
	background-image: url('/20181016082423/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border-style: none;
	border-color: #fff;
	border-radius: 5px;
}

.invisible-block {
	height: 80px;
	background-color: rgba(0, 0, 0, 0);
	position: absolute;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.slider-container-2 {
	width: 60%;
	justify-content: flex-end;
	align-items: flex-end;
	display: flex;
	overflow: visible;
}

.lynx-block-hero-2 {
	z-index: 10;
	width: 35%;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-left: 12%;
	display: flex;
	position: absolute;
	top: 194px;
	left: 0;
}

.icon-quick-link {
	color: #1c3d66;
	margin-top: 0;
	margin-right: 0.5rem;
	padding-right: 0;
	font-family: Open Sans, sans-serif;
	font-size: 0.95rem;
	font-weight: 700;
	line-height: 1.1rem;
	text-decoration: none;
	transition: all 0.25s;
	display: flex;
}

.icon-quick-link:hover {
	background-position: 100% 70%;
}

.icon-wrapper {
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	border-top: 0 solid rgba(0, 0, 0, 0);
	border-bottom: 0 solid rgba(0, 0, 0, 0);
	border-radius: 0;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 0;
	display: flex;
}

.h3-headings {
	max-width: 500px;
	color: #1c3d66;
	text-align: center;
	letter-spacing: 0;
	text-indent: 0;
	text-transform: none;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	border-right: 5px #3eedb6;
	margin-top: 1rem;
	margin-bottom: 0;
	margin-right: 0;
	padding-right: 0;
	font-family: Open Sans, sans-serif;
	font-size: 1.1rem;
	font-weight: 700;
	line-height: 1rem;
	display: inline-block;
}

.h3-headings.icon-headings {
	width: 100%;
	max-width: none;
	color: #fff;
	text-align: left;
	letter-spacing: 0;
	margin-top: 0;
	margin-bottom: 1.25rem;
	padding-left: 20px;
	font-family: minerva-modern, sans-serif;
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 1.5rem;
}

.arrow-link-block {
	width: 2rem;
	height: 2rem;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	margin-top: 4rem;
	display: flex;
	position: static;
	top: auto;
	bottom: 3rem;
	left: auto;
	right: 3rem;
}

.arrow-link-block.quick-links {
	width: 1.15rem;
	height: 1.15rem;
	background-color: #1c3d66;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	margin-top: 0;
	display: flex;
}

.main-button-apply {
	height: auto;
	color: #fff;
	background-color: #007050;
	border: 2px #84d5f7;
	border-radius: 100px;
	align-items: center;
	padding: 9px 25px;
	font-weight: 600;
	line-height: 20px;
	text-decoration: none;
	transition: all 0.45s;
	display: inline-block;
}

.main-button-apply:hover {
	background-color: #1a1a1a;
}

.main-button-apply.dark-background {
	z-index: 10;
	color: #0f5d4b;
	background-color: #fff;
	border-color: #fff;
	border-radius: 8px;
	margin-top: 20px;
	font-family: Outfit, sans-serif;
	display: none;
}

.main-button-apply.dark-background:hover {
	color: #fff;
	background-color: #0f5d4b;
}

.img-hero {
	width: 100%;
	height: 100%;
}

.div-block-81 {
	align-items: center;
	margin-top: 2rem;
	display: none;
}

.hero-div {
	height: 100%;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1.25fr 1fr;
	grid-auto-columns: 1fr;
	display: block;
}

.bold-text-7 {
	color: #fff;
	font-family: Outfit, sans-serif;
	font-size: 22px;
	font-weight: 300;
	line-height: 27px;
}

.quick-links-wrapper {
	z-index: 7;
	width: 70%;
	height: 180px;
	flex-direction: column;
	align-items: flex-end;
	margin-top: -220px;
	padding-bottom: 0;
	padding-left: 10%;
	display: none;
	position: relative;
}

.icon-img {
	width: 60%;
	height: auto;
	max-width: none;
	opacity: 1;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 0;
	display: block;
}

.icon-link-wrapper {
	width: 250px;
	height: 200px;
	opacity: 1;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.7);
	border: 1px solid rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
	margin-right: 0;
	padding-top: 1rem;
	padding-bottom: 0;
	padding-right: 0;
	line-height: 25px;
	text-decoration: none;
	display: flex;
}

.footer-signoff-link-2 {
	color: #fff;
	letter-spacing: 1px;
	font-family: Outfit, sans-serif;
	font-size: 0.8rem;
	font-weight: 300;
	text-decoration: none;
	transition: color 0.3s;
	border-bottom: 1px solid transparent;
}

.footer-signoff-link-2:hover {
	color: #fff;
}

.footer-container {
	width: 100%;
	height: auto;
	background-color: #fff;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 0;
	padding-left: 0%;
	padding-right: 0%;
	display: flex;
}

.column-3 {
	height: 100%;
	justify-content: flex-start;
	align-items: center;
	padding-left: 20px;
	padding-right: 0;
	font-family: Heebo, sans-serif;
	display: flex;
}

.div-block-15 {
	width: 100%;
	border-top: 0 solid #fff;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	margin-left: 5%;
	margin-right: 5%;
	padding-left: 5%;
	padding-right: 5%;
	display: flex;
}

.columns {
	width: 100%;
	height: auto;
	color: rgba(0, 0, 0, 0);
	border-top: 0 solid #fff;
	justify-content: space-between;
	align-items: center;
	margin-top: 0;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
}

.footer-signoff-link-wrap {
	display: inline-block;
}

.grip-logo-text-box-2 {
	color: #000;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
	font-size: 0.5rem;
	font-weight: 700;
	line-height: 0.5rem;
	text-decoration: none;
}

.grip-logo-text-box-2.webdevby-white {
	width: auto;
	color: #fff;
	letter-spacing: 0;
	font-family: Heebo, sans-serif;
	font-weight: 400;
}

.footer-copyright {
	color: #fff;
	margin-bottom: 0;
	margin-right: 1.5rem;
	font-family: Outfit, sans-serif;
	font-size: 0.9rem;
	font-weight: 300;
	display: inline-block;
}

.footer-copyright h1 {
	font-family: Outfit, sans-serif;
	font-size: 0.9rem;
	font-weight: 300;
	color: #fff;
	display: inline;
	line-height: inherit;
	margin: 0px;
	padding: 0px;
}

.column-2 {
	justify-content: flex-end;
	padding-right: 20px;
	display: flex;
}

.footer-signoff-links {
	border-bottom: 0 solid #1d1d1b;
	margin-right: 1rem;
	line-height: 1.25rem;
	display: inline-block;
}

.footer-signoff-links:hover {
	color: #fff;
	border-bottom-color: #998868;
	text-decoration: underline;
}

.uui-page-padding-11 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.heading-6 {
	color: #2d4578;
	text-transform: uppercase;
	margin-top: 0;
	font-family: minerva-modern, sans-serif;
	font-size: 40px;
	font-weight: 400;
}

.uui-button-icon-8 {
	width: 1.25rem;
	height: 1.25rem;
	min-height: 1.25rem;
	min-width: 1.25rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
}

.uui-testimonial05_slide-nav {
	height: 1.75rem;
	font-size: 0.5rem;
}

.uui-testimonial05_client-image-wrapper {
	margin-top: 2rem;
	margin-bottom: 1rem;
	display: none;
}

.uui-testimonial05_arrow {
	width: 3.5rem;
	height: 3.5rem;
	color: #667085;
	background-color: #fff;
	border: 1px solid #eaecf0;
	border-radius: 100%;
	justify-content: center;
	align-items: center;
	transition: all 0.3s;
	display: flex;
}

.uui-testimonial05_arrow:hover {
	background-color: #f9fafb;
}

.uui-testimonial05_arrow.hide-mobile-landscape {
	color: #667085;
}

.uui-heading-medium-8 {
	color: #101828;
	margin-top: 0;
	margin-bottom: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 2.25rem;
	font-weight: 600;
	line-height: 1.3;
}

.uui-heading-medium-8.text-weight-medium {
	color: #47494c;
	font-size: 18px;
	font-weight: 500;
}

.uui-testimonial05_logo-wrapper {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 2rem;
	display: flex;
}

.uui-testimonial05_component {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-bottom: 4rem;
}

.uui-testimonial05_slide {
	padding-left: 4rem;
	padding-right: 4rem;
}

.uui-container-large-11 {
	width: 70%;
	max-width: 80rem;
	margin-left: auto;
	margin-right: auto;
}

.uui-testimonial05_content {
	width: 80%;
	height: 100%;
	max-width: 64rem;
	text-align: center;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.testimonias-one-by-one {
	background-color: rgba(45, 69, 120, 0.2);
	margin-top: 0;
	padding-top: 4rem;
	display: none;
}

.uui-text-size-medium-9 {
	color: #475467;
	letter-spacing: normal;
	margin-top: 3rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	line-height: 1.5;
}

.uui-testimonial05_client-image {
	width: 4rem;
	height: 4rem;
	min-height: 4rem;
	min-width: 4rem;
	object-fit: cover;
	border-radius: 100%;
}

.uui-padding-vertical-xhuge-11 {
	padding-top: 1rem;
	padding-bottom: 4rem;
}

.uui-heading-tiny-2 {
	color: #101828;
	letter-spacing: normal;
	margin-top: 0;
	margin-bottom: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1.125rem;
	font-weight: 600;
	line-height: 1.5;
}

.slide-3 {
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
}

.slide-4 {
	background-position: 0 0, 50% 100%;
	background-size: auto, cover;
}

.slider-2 {
	height: 600px;
	top: 150px;
}

.heading-7 {
	color: #fff;
	font-family: Outfit, sans-serif;
	font-size: 60px;
	font-weight: 700;
	line-height: 58px;
}

.paragraph-2 {
	font-family: minerva-modern, sans-serif;
}

.promo-counter {
	opacity: 1;
	background-color: rgba(0, 0, 0, 0);
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	display: none;
}

.content-wrap-stats {
	width: 100%;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	text-align: left;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 20px;
	flex-direction: column;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-items: flex-start;
	justify-items: start;
	padding: 0;
	display: flex;
}

._1-3-grid {
	grid-column-gap: 25px;
	grid-row-gap: 25px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
}

._1-3-grid._50-p {
	grid-column-gap: 50px;
	grid-row-gap: 50px;
	grid-column-gap: 50px;
	grid-row-gap: 50px;
	align-items: flex-start;
	justify-content: flex-start;
}

.main-section {
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 100px;
	padding: 40px 5% 10px;
	display: flex;
}

._2-images-wrapper {
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 100px;
	display: flex;
	position: relative;
}

.heading-10 {
	color: #374957;
	font-family: Outfit, sans-serif;
	font-size: 35px;
	font-weight: 400;
}

.div-block-120 {
	width: 100%;
	border-bottom: 0 solid #1d1d1b;
	flex-direction: row;
	margin-left: 5%;
	padding-left: 5%;
	display: none;
}

.dividel-footer {
	width: 20%;
	height: 35px;
	border-top: 0 solid #166b3e;
	border-bottom: 0 solid #1d1d1b;
	flex-direction: row;
	margin-left: 0;
	margin-right: auto;
	padding-left: 0%;
	display: none;
}

.heading-10-copy {
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
	display: none;
}

.nav-link-enquire {
	color: #fff;
	text-transform: uppercase;
	background-color: #00e5e0;
	border: 0 solid #998868;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 1rem;
	font-family: minerva-modern, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	display: none;
}

.nav-link-enquire:hover {
	color: #0a0442;
	-webkit-text-stroke-color: #0a0442;
	background-color: #fff;
}

.nav-link-enquire.w--current {
	color: #000;
}

.icon-wrap-copy {
	width: 6rem;
	height: 6rem;
	width: 6rem;
	height: 6rem;
	width: 6rem;
	height: 6rem;
	background-color: #fff;
	border-radius: 200px;
	justify-content: center;
	align-items: center;
	display: none;
	overflow: hidden;
}

.link-09 {
	width: 78px;
	grid-column-gap: 1rem;
	color: #131313;
	justify-content: flex-start;
	align-self: center;
	align-items: center;
	text-decoration: none;
	display: flex;
	position: relative;
	overflow: hidden;
}

.button-with-arrow {
	width: auto;
	height: 50px;
	opacity: 0.8;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	padding-left: 0;
	padding-right: 19px;
	display: flex;
	position: relative;
	overflow: hidden;
	border-bottom: 1px solid transparent;
	text-decoration: none;
}

.button-with-arrow:active {
	opacity: 1;
}

.text-cta {
	z-index: 1;
	color: #2d4578;
	text-transform: uppercase;
	font-family: Heebo, sans-serif;
	font-size: 14px;
	font-weight: 400;
	position: relative;
}

.blob {
	width: 40px;
	height: 40px;
	background-color: #998868;
	border-radius: 99999px;
	position: absolute;
	top: auto;
	bottom: auto;
	left: 6px;
	right: auto;
}

.block-arrow {
	width: 80px;
	height: 50px;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: flex-start;
	align-items: center;
	margin-left: 10px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.null-arrow {
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
}

.arrow-stem {
	width: 68px;
	height: 2px;
	background-color: #2d4578;
}

.div-block-125 {
	width: 100%;
	background-color: #053061;
	border-top: 5px solid #166b3e;
	margin-top: 20px;
}

.div-block-127 {
	align-items: center;
	display: flex;
}

.bold-text-8 {
	font-family: Outfit, sans-serif;
}

.services-icons {
	max-width: 1000px;
	justify-content: space-around;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 60px;
	padding-bottom: 60px;
	display: flex;
	transform: translate(0);
}

.link-block {
	width: 15%;
	height: 200px;
	color: #383838;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: Open Sans, sans-serif;
	font-weight: 300;
	text-decoration: none;
	transition: all 0.45s;
	display: flex;
}

.link-block:hover {
	transform: scale(1.1);
}

.service-icon {
	width: 100%;
	max-width: 120px;
	margin-top: 0;
	margin-bottom: 0;
}

.service-title {
	color: #213064;
	text-align: center;
	text-transform: none;
	margin-top: 10px;
	font-family: Outfit, sans-serif;
	font-size: 17px;
	font-weight: 600;
	line-height: 25px;
}

.service-title.acount,
.service-title.mobile-banking {
	color: #67011f;
	font-family: Outfit, sans-serif;
}

.service-title.atm {
	color: #67011f;
	font-family: Outfit, sans-serif;
	font-weight: 600;
}

.service-title.mortgage {
	color: #67011f;
	text-align: center;
	font-family: Outfit, sans-serif;
	font-weight: 600;
}

.lynx-block-center {
	text-align: center;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.lynx-paragraph-slide {
	color: #797c83;
	margin-bottom: 20px;
	font-family: Outfit, sans-serif;
	font-size: 16px;
	line-height: 165%;
}

.lynx-client {
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.lynx-slider {
	width: 100%;
	height: 380px;
	background-color: #fff;
	border-radius: 30px;
	margin-top: 14px;
	box-shadow: 0 60px 60px -15px rgba(31, 32, 34, 0.1);
}

.lynx-heading {
	max-width: 740px;
	color: #1f2022;
	margin-top: 0;
	margin-bottom: 36px;
	font-family: Outfit, sans-serif;
	font-size: 46px;
	font-weight: 600;
	line-height: 120%;
}

.lynx-block-slide-reviews {
	height: 100%;
	display: flex;
}

.lynx-left-arrow {
	width: 55px;
	height: 55px;
	background-color: #1f2022;
	background-image: url('/20181016082423/assets/images/Arrow_1.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto;
	border-radius: 50%;
	left: -20px;
}

.link-4 {
	color: #121278;
	margin-top: 0;
	font-style: normal;
	font-weight: 600;
	text-decoration: none;
	display: none;
}

.link-4:hover {
	color: #282556;
	text-decoration: underline;
}

.lynx-heading-client {
	color: #1f2022;
	margin-top: 0;
	margin-bottom: 2px;
	font-family: Outfit, sans-serif;
	font-size: 26px;
	font-weight: 700;
	line-height: 132%;
}

.services-slider {
	width: 100%;
	max-width: 1080px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-top: 100px;
	margin-left: auto;
	margin-right: auto;
	display: none;
}

.lynx-right-arrow {
	width: 55px;
	height: 55px;
	background-color: #1f2022;
	background-image: url('/20181016082423/assets/images/Arrow_3.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto;
	border-radius: 50%;
	right: -20px;
}

.lynx-block-slide {
	width: 50%;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 50px 8%;
	display: flex;
}

.lynx-text-client {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-left: 0;
	display: flex;
}

.lynx-slide-nav {
	display: none;
}

.lynx-image-slide {
	width: 50%;
	height: 100%;
	object-fit: cover;
	border-top-left-radius: 30px;
	border-bottom-right-radius: 200px;
	border-bottom-left-radius: 30px;
	display: inline-block;
}

.parallax-image-1 {
	height: 450px;
	background-position: 0 0, 50% 72%;
	background-size: auto, cover;
	background-attachment: scroll, fixed;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.div-block-128 {
	width: 60%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
}

.heading-3 {
	color: #fff;
	text-align: center;
	font-family: Outfit, sans-serif;
	font-size: 50px;
	font-weight: 300;
	line-height: 55px;
}

.text-block-35 {
	color: #fff;
	text-align: center;
	font-family: Montserrat, sans-serif;
	font-size: 25px;
	font-weight: 700;
	line-height: 40px;
	display: none;
}

.button-no-outline {
	height: auto;
	background-color: #4f95d0;
	border: 2px #4f95d0;
	border-radius: 100px;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 40px;
	padding: 9px 25px;
	font-family: Montserrat, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: none;
}

.button-no-outline:hover {
	color: #213064;
	background-color: #fff;
	border-style: none;
	border-color: #fff;
}

.arrow-2 {
	width: auto;
	height: auto;
	position: static;
	overflow: hidden;
}

.arrow-2:hover {
	width: auto;
	height: auto;
}

.div-block-5 {
	height: 60%;
	border-radius: 5px;
	justify-content: center;
	display: flex;
	overflow: hidden;
}

.div-block-7 {
	width: auto;
	height: auto;
	position: static;
	overflow: hidden;
}

.div-block-7:hover {
	width: auto;
	height: auto;
}

.heading-2 {
	color: #67011f;
	-webkit-text-stroke-color: #67011f;
	margin-top: 10px;
	font-size: 26px;
	font-weight: 600;
	line-height: 35px;
}

.text-block-3 {
	color: #374957;
	font-family: Outfit, sans-serif;
}

.div-block-6 {
	width: auto;
	height: 45px;
	align-items: center;
	margin-bottom: 20px;
	display: flex;
	overflow: hidden;
}

.button-3 {
	color: #166b3e;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-family: Outfit, sans-serif;
	font-weight: 600;
	transition: all 0.45s;
}

.button-3:hover {
	color: #67011f;
	-webkit-text-stroke-color: #160042;
	padding-right: 0;
}

.grey-arrow-3 {
	width: 0;
	margin-left: 10px;
}

.div-block-2 {
	width: 30%;
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	align-items: flex-start;
	font-family: Outfit, sans-serif;
	display: flex;
}

.news-section {
	color: #374957;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 60px 140px 40px;
	display: flex;
}

.div-block-12 {
	width: 100%;
	justify-content: space-between;
	display: flex;
}

.image-3 {
	width: 120%;
	max-width: none;
	border-radius: 5px;
}

.image-3.img-zoom {
	transition: all 0.45s;
}

.image-3.img-zoom:hover {
	transform: scale(1.1);
}

.features_grid {
	grid-column-gap: 2.5rem;
	grid-row-gap: 2rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.features_header {
	grid-column-gap: 3rem;
	grid-row-gap: 4rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.container-large {
	width: 100%;
	max-width: 80rem;
	margin-left: auto;
	margin-right: auto;
}

.container-large.is-huge {
	max-width: 120rem;
}

.features_item {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	background-color: #fff;
	border-radius: 1.25rem;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-content: start;
	align-items: start;
	padding: 2rem;
	display: grid;
}

.icon-small {
	height: 2rem;
}

.section_features {
	background-color: #edf7ff;
	display: none;
}

.padding-section-medium {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

.features_components {
	grid-column-gap: 4rem;
	grid-row-gap: 4rem;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.padding-global {
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}

.cta_content-right {
	color: #fff;
	background-color: #166b3e;
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 5rem;
	display: flex;
}

.cta_component {
	height: 100%;
	grid-column-gap: 0rem;
	grid-row-gap: 0rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.padding-bottom {
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
}

.section_cta {
	display: none;
}

.team_grid {
	width: 50%;
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-content: center;
	align-items: center;
	justify-items: center;
	display: flex;
}

.team_image {
	width: 100%;
	height: 28rem;
	object-fit: cover;
}

.section_team {
	margin-bottom: 4rem;
}

.text-size-small {
	font-size: 0.875rem;
}

.text-size-small.text-style-muted {
	font-family: Outfit, sans-serif;
}

.about_team_header {
	flex-direction: column;
	flex: 0 auto;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	margin-bottom: 4rem;
	display: flex;
}

.team_description {
	color: #fff;
	margin-bottom: 0;
	font-family: Outfit, sans-serif;
	font-size: 0.875rem;
	font-weight: 400;
}

.padding-section-xsmall {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.team_content {
	color: #fff;
	background-color: rgba(22, 107, 62, 0.8);
	border-radius: 1.25rem 1.25rem 0 0;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 1.5rem 2rem 1rem;
	position: absolute;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.team_item {
	width: 100%;
	border-radius: 0;
	position: relative;
	overflow: hidden;
}

.about_team_component {
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	display: flex;
	position: relative;
}

.team_name {
	color: #fff;
	margin-top: 10px;
	font-family: Outfit, sans-serif;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.5;
}

.max-width-large {
	width: 100%;
	max-width: 48rem;
}

.heading-11 {
	text-transform: none;
	font-family: Outfit, sans-serif;
	font-size: 60px;
	line-height: 55px;
}

.text-cta-2 {
	z-index: 1;
	color: #fff;
	text-transform: uppercase;
	font-family: Heebo, sans-serif;
	font-size: 14px;
	font-weight: 400;
	position: relative;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.blob-2 {
	width: 40px;
	height: 40px;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 99999px;
	display: block;
	position: absolute;
	top: auto;
	bottom: auto;
	left: 6px;
	right: auto;
}

.arrow-stem-2 {
	width: 68px;
	height: 2px;
	background-color: #fff;
}

.heading-12 {
	color: #67011f;
	font-family: Outfit, sans-serif;
	font-size: 50px;
}

.paragraph-8 {
	font-family: Outfit, sans-serif;
	display: none;
}

.button-with-arrow-copy {
	width: auto;
	height: 50px;
	opacity: 0.8;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: flex-start;
	align-items: center;
	margin-top: 0;
	padding-left: 0;
	padding-right: 19px;
	display: flex;
	position: relative;
	overflow: hidden;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.button-with-arrow-copy:active {
	opacity: 1;
}

.button-with-arrow-promo {
	width: auto;
	height: 50px;
	opacity: 0.8;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	padding-left: 19px;
	padding-right: 19px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.button-with-arrow-promo:active {
	opacity: 1;
}

.footer-social-icon-image {
	float: left;
	color: #025e8a;
}

.footer-social-icon-image.youtube {
	padding: 1px;
}

.cta_content-right-left-2 {
	color: #fff;
	background-color: #166b3e;
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 8rem 5rem;
	display: flex;
}

.home-content-section {
	height: auto;
	background-color: #fff;
	justify-content: center;
	align-items: center;
	display: flex;
}

.home-content-section.banner {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	margin-top: 102px;
	display: flex;
}

.section-content {
	width: auto;
	height: auto;
	max-width: 1600px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 0;
	padding: 4rem;
	display: flex;
	position: static;
	left: 5%;
}

.section-inner-content {
	flex-direction: column;
	align-items: center;
	display: flex;
}

.content-headings {
	width: 100%;
	max-width: none;
	color: #67011f;
	text-align: center;
	letter-spacing: 1px;
	text-indent: 0;
	text-transform: capitalize;
	border-right: 5px #3eedb6;
	margin-top: 20px;
	margin-bottom: 0.5rem;
	margin-right: 0;
	padding-right: 0;
	font-family: Outfit, sans-serif;
	font-size: 55px;
	font-weight: 700;
	line-height: 3.5rem;
	display: inline-block;
}

.paragraph-9 {
	width: auto;
	max-width: 800px;
	color: #374957;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 2.25rem;
	padding-left: 0;
	padding-right: 0;
	font-family: Outfit, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.5rem;
}

.button-5 {
	z-index: 5;
	color: #fff;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	cursor: pointer;
	background-color: #053061;
	background-image: url('/20181016082423/assets/images/white-arrow-icon-01.svg');
	background-position: 87%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 0;
	margin-right: 20px;
	padding: 0.75rem 3.75rem 0.75rem 1.25rem;
	font-family: Outfit, sans-serif;
	font-size: 0.85rem;
	font-weight: 500;
	transition: all 0.4s, background-color 0.4s;
	position: static;
	top: auto;
}

.button-5:hover {
	opacity: 1;
	color: #fff;
	background-color: #166b3e;
}

.uui-logo_component-6 {
	width: 100%;
	height: 60px;
	flex-wrap: nowrap;
	align-items: center;
	display: none;
}

.footer_links-col-5 {
	color: #374957;
	flex-direction: column;
	align-items: flex-start;
	font-family: Outfit, sans-serif;
	display: flex;
}

.div-block-129 {
	justify-content: space-around;
	margin-top: 50px;
	margin-bottom: 30px;
	display: flex;
}

.footer_links-col-first {
	color: #374957;
	flex-direction: column;
	align-items: flex-start;
	font-family: Outfit, sans-serif;
	display: none;
}

.footer_links-col-6 {
	color: #374957;
	flex-direction: column;
	align-items: flex-start;
	font-family: Outfit, sans-serif;
	display: flex;
}

.text-block-37 {
	color: #374957;
	margin-top: 0.5rem;
	font-weight: 300;
}

.text-block-37 a {
	color: #374957;
	font-weight: 300;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}


.footer_links-col-7 {
	color: #374957;
	flex-direction: column;
	align-items: flex-start;
	font-family: Outfit, sans-serif;
	display: none;
}

.div-block-130 {
	width: auto;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.footer-social-icon-link-2 {
	width: 2.5rem;
	height: 2.5rem;
	min-width: 0;
	float: none;
	color: #2a779b;
	background-color: #048060;
	border-radius: 20px;
	margin: 0.5rem 0.5rem 0.25rem 0;
	padding: 4px;
	transition: all 0.2s;
}

.footer-social-icon-link-2:hover {
	background-color: #0e9346;
}

.link-list-title-2 {
	width: 100%;
	color: #374957;
	text-align: center;
	text-transform: capitalize;
	margin-top: 10px;
	margin-bottom: 0.5rem;
	font-family: Outfit, sans-serif;
	font-size: 1.125rem;
	font-weight: 600;
	display: flex;
}

.footer_title-2 {
	color: #374957;
	font-size: 1.125rem;
	font-weight: 600;
}

.footer_link-2 {
	color: #374957;
	margin-top: 0.5rem;
	font-weight: 300;
	transition: opacity 0.2s;
}

.footer_link-2:hover {
	opacity: 1;
	color: #67011f;
}

.link-text-3 {
	opacity: 1;
	color: #fff;
	letter-spacing: 0;
	text-transform: capitalize;
	border-bottom: 1px rgba(255, 255, 255, 0.35);
	padding-bottom: 5px;
	font-family: Outfit, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 0.95rem;
	text-decoration: none;
	transition: opacity 0.3s;
}

.link-text-3:hover {
	opacity: 1;
	color: #5fa4ce;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.div-block-131 {
	z-index: 2000;
	width: 100%;
	height: 50px;
	background-color: #053061;
	justify-content: flex-end;
	padding: 0 2%;
	display: flex;
	position: fixed;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
	overflow: hidden;
}

.login-button-main-2 {
	height: auto;
	outline-offset: -2px;
	color: #fff;
	background-color: #007050;
	background-image: url('/20181016082423/assets/images/lock.svg');
	background-position: 9%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 100px;
	outline: 2px solid #007050;
	align-items: center;
	padding: 9px 25px 9px 40px;
	font-family: Ubuntu, Helvetica, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: none;
}

.login-button-main-2:hover {
	color: #fff;
	background-color: #0f5d4b;
	outline: 3px solid rgba(0, 0, 0, 0);
}

.search-input-3 {
	height: 38px;
	border-radius: 20px;
	margin-bottom: 0;
	margin-right: -38px;
}

.search-3 {
	width: 38px;
	height: 38px;
	opacity: 1;
	align-items: center;
	margin: 10px 20px;
	display: flex;
	overflow: hidden;
}

.search-3.hidden {
	display: none;
}

.nav-link-copy {
	color: #fff;
	text-transform: capitalize;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Exo, sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	display: none;
}

.nav-link-copy:hover {
	color: #00e5e0;
}

.nav-link-copy.w--current {
	color: #000;
}

.nav-link-copy {
	color: #fff;
	text-transform: capitalize;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Exo, sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
}

.nav-link-copy:hover {
	color: #00e5e0;
}

.nav-link-copy.w--current {
	color: #000;
}

.image-31 {
	padding: 15px 5px;
	display: block;
}

.button-with-arrow-parallax {
	width: auto;
	height: 50px;
	opacity: 0.8;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	padding-left: 0;
	padding-right: 19px;
	display: flex;
	position: relative;
	overflow: hidden;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.button-with-arrow-parallax:active {
	opacity: 1;
}

.team_item_promo {
	width: 50%;
	cursor: pointer;
	border-radius: 0;
	position: relative;
	overflow: hidden;
}

.text-size-small-copy {
	font-size: 0.875rem;
}

.text-size-small-copy.text-style-muted {
	font-family: Outfit, sans-serif;
	display: none;
}

.text-cta-3 {
	z-index: 1;
	color: #fff;
	text-transform: uppercase;
	font-family: Heebo, sans-serif;
	font-size: 14px;
	font-weight: 400;
	position: relative;
}

.team_description-copy {
	color: #fff;
	margin-bottom: 0;
	font-family: Outfit, sans-serif;
	font-size: 0.875rem;
	font-weight: 400;
	display: none;
}

.arrow-stem-3 {
	width: 68px;
	height: 2px;
	background-color: #fff;
}

.heading-13 {
	text-transform: none;
	font-family: PT Serif, serif;
	font-size: 60px;
	font-weight: 400;
	line-height: 55px;
	color: #fff;
}

.team_content-2 {
	color: #fff;
	background-color: rgba(4, 128, 96, 0.8);
	border-radius: 0;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 1.5rem 2rem 1rem;
	position: absolute;
	top: auto;
	bottom: 22%;
	left: 0%;
	right: 25%;
}

.team_description-copy-2 {
	color: #fff;
	margin-bottom: 0;
	font-family: Outfit, sans-serif;
	font-size: 0.875rem;
	font-weight: 400;
	display: none;
}

.team_name-copy-2 {
	color: #fff;
	margin-top: 10px;
	font-family: PT Serif, serif;
	font-size: 60px;
	font-weight: 500;
	line-height: 1.5;
	display: none;
}

.two-box-promo {
	display: flex;
}

.team_item-copy {
	width: 100%;
	width: 100%;
	border-radius: 0;
	display: none;
	position: relative;
	overflow: hidden;
}

.counter--2 {
	width: auto;
	color: #67011f;
	-webkit-text-stroke-color: #374957;
	font-family: PT Serif, serif;
	font-size: 80px;
	font-weight: 700;
	line-height: 1;
}

.counter-3-2 {
	width: auto;
	color: #67011f;
	font-family: PT Serif, serif;
	font-size: 80px;
	font-weight: 700;
	line-height: 1;
}

.counter-3-2._2ch {
	width: auto;
}

.counter-2-2 {
	width: auto;
	color: #67011f;
	font-family: PT Serif, serif;
	font-size: 80px;
	font-weight: 700;
	line-height: 1;
}

.spacer-blank-2 {
	width: 100%;
	height: 50px;
	text-align: center;
	justify-content: center;
	font-size: 1px;
	display: flex;
}

.image-32 {
	margin-left: 10px;
	display: none;
}

.image-33 {
	display: none;
}

.main-container-2 {
	width: 100%;
	max-width: 1200px;
	flex-direction: column;
	align-items: flex-start;
	display: flex;
}

.image-34 {
	display: none;
}

.content-wrap-stats-2 {
	width: 100%;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	text-align: left;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 20px;
	flex-direction: column;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-items: center;
	justify-items: start;
	padding: 0;
	display: flex;
}

.plus-icon-2 {
	width: 100%;
	height: 30px;
	justify-content: center;
	align-items: center;
	padding-left: 0;
	display: flex;
}

.plus-icon-2.space {
	margin-left: -15px;
}

.h3-2 {
	width: 23ch;
	color: #000;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 0;
	font-family: Outfit, sans-serif;
	font-size: 19px;
	font-weight: 300;
	line-height: 26px;
}

.footer-grip-link-2 {
	width: 133px;
	height: 29px;
	min-width: 0;
	cursor: pointer;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 130px 29px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	text-decoration: none;
	transition: all 0.2s;
}

.footer-grip-link-2.white-logo {
	width: 140px;
	background-size: 150px 29px;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
}

.counter-symbol {
	color: #67011f;
	font-family: PT Serif, serif;
	font-size: 80px;
	font-weight: 700;
	line-height: 1;
}

.separator-line {
	width: 100%;
	height: 1px;
	background-color: #07693a;
}

.mega-desktop {
	z-index: 2000;
	width: 100%;
	background-color: #fff;
	justify-content: center;
	align-items: center;
	padding-left: 2%;
	padding-right: 2%;
	display: flex;
	position: fixed;
	top: 50px;
	overflow: visible;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.bold-text-10 {
	color: #0a0442;
}

.brand-2 {
	background-color: #fff;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-left: 0;
	display: flex;
}

.uui-navbar02_container-2 {
	width: 100%;
	height: 100%;
	max-width: none;
	justify-content: space-between;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.nav-link-2 {
	color: #053061;
	text-transform: uppercase;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Outfit, sans-serif;
	font-size: 17px;
	font-weight: 500;
	line-height: 24px;
	transition: all 0.3s;
}

.nav-link-2:hover {
	color: #67011f;
}

.nav-link-2.w--current {
	color: #000;
}

.uui-navbar02_menu-dropdown-2 {
	margin-bottom: 8px;
	position: static;
}

.div-block-132 {
	width: 25px;
	height: 25px;
	cursor: pointer;
	background-image: url('/20181016082423/assets/images/exit-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
}

.search-icon-2 {
	width: 35px;
	height: 35px;
	min-height: auto;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border: 0.25px rgba(0, 0, 0, 0);
	border-radius: 5px;
	padding: 0 0.25rem;
	transition: all 0.3s, background-color 0.3s;
}

.search-icon-2:hover {
	background-color: #00e5e0;
	background-image: url('/20181016082423/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border-style: none;
	border-color: #fff;
	border-radius: 5px;
}

.mega-menu-mobile {
	z-index: 1000;
	width: 100%;
	background-color: #fff;
	justify-content: center;
	align-items: center;
	padding-left: 2%;
	padding-right: 2%;
	display: none;
	position: fixed;
	top: 50px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.text-block-38 {
	color: #053061;
	text-transform: uppercase;
	font-family: Outfit, sans-serif;
	font-size: 17px;
	font-weight: 500;
	line-height: 24px;
}

.text-block-38:hover {
	color: #67011f;
}

.nav-link-copy-2 {
	color: #fff;
	text-transform: capitalize;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Exo, sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	display: none;
}

.nav-link-copy-2:hover {
	color: #00e5e0;
}

.nav-link-copy-2.w--current {
	color: #000;
}

.uui-navbar02_dropdown-toggle-3 {
	color: #475467;
	align-items: center;
	padding: 1.75rem 2.5rem 1.75rem 0.5rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar02_dropdown-toggle-3:hover {
	color: #344054;
}

.uui-navbar02_dropdown-toggle-3.w--open {
	height: 100%;
	padding-bottom: 1.75rem;
}

.text-block-39 {
	color: #fff;
	font-family: Montserrat, sans-serif;
	font-size: 30px;
	font-weight: 800;
}

.image-35 {
	background-color: #fff;
	border-radius: 8px;
	padding: 5px;
	position: relative;
	bottom: 40px;
}

.uui-navbar02_item-heading-3 {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: capitalize;
	margin-bottom: 0.25rem;
	font-family: Exo, sans-serif;
	font-weight: 400;
}

.uui-navbar02_item-heading-3:hover {
	color: #5fa4ce;
	font-family: Exo, sans-serif;
}

.nav-link-copy-3 {
	color: #fff;
	text-transform: capitalize;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Exo, sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
}

.nav-link-copy-3:hover {
	color: #00e5e0;
}

.nav-link-copy-3.w--current {
	color: #000;
}

.uui-navbar02_menu-3 {
	flex: 1;
	justify-content: flex-end;
	align-items: center;
	display: flex;
	position: static;
}

.uui-navbar02_dropdown-list-3 {
	box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.uui-navbar02_dropdown-list-3.w--open {
	width: auto;
	max-width: none;
	min-width: auto;
	background-color: #053061;
	padding-left: 0%;
	padding-right: 0%;
	position: absolute;
	top: 5rem;
	bottom: auto;
	left: auto;
	right: auto;
	overflow: hidden;
}

.search-4 {
	justify-content: flex-end;
	align-items: center;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: none;
}

.uui-dropdown-icon-2 {
	width: 1.25rem;
	height: 1.25rem;
	color: #053061;
	justify-content: center;
	align-items: center;
	margin-right: 1rem;
	display: flex;
	position: absolute;
	top: auto;
	bottom: auto;
	left: auto;
	right: 0%;
}

.nav-link-enquire-2 {
	color: #fff;
	text-transform: uppercase;
	background-color: #00e5e0;
	border: 0 solid #998868;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 1rem;
	font-family: minerva-modern, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	display: none;
}

.nav-link-enquire-2:hover {
	color: #0a0442;
	-webkit-text-stroke-color: #0a0442;
	background-color: #fff;
}

.nav-link-enquire-2.w--current {
	color: #000;
}

.uui-navbar02_menu-left-3 {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	justify-content: flex-end;
	margin-top: 0;
	margin-right: 16px;
	display: flex;
}

.uui-navbar02_menu-button-4 {
	padding: 0;
}

.image-31-copy {
	padding: 15px 5px;
	display: block;
}

.tab-link-established-nationally {
	color: #67011f;
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 4px solid rgba(0, 0, 0, 0);
	font-family: PT Serif, serif;
	font-size: 20px;
}

.tab-link-established-nationally.w--current {
	color: #67011f;
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 5px solid #67011f;
	font-family: PT Serif, serif;
	font-size: 20px;
}

.tab-link-growing-in-iowa {
	color: #67011f;
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 4px solid rgba(0, 0, 0, 0);
	font-size: 20px;
}

.tab-link-growing-in-iowa.w--current {
	background-color: rgba(0, 0, 0, 0);
	border-bottom-width: 5px;
	border-bottom-color: #67011f;
}

.tab-pane-established-nationally {
	padding-top: 100px;
	padding-bottom: 50px;
}

.tabs-menu {
	justify-content: center;
	display: flex;
}

.tab-pane-growing-in-iowa {
	padding-top: 100px;
	padding-bottom: 50px;
}

.two-part-button-wrap {
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	position: relative;
}

.left-nav-col {
	width: 20%;
	height: 100%;
	min-width: 220px;
	background-color: rgba(198, 234, 228, 0.4);
	border-radius: 20px;
	flex: none;
	margin-right: 10px;
}

.left-nav-list {
	margin-top: 0;
	margin-bottom: 1.5em;
	padding-left: 0;
}

.button-6 {
	color: #fff;
	text-align: center;
	background-color: #f58a07;
	border-radius: 1.9375rem;
	justify-content: center;
	align-items: center;
	padding: 0.75rem 2rem;
	font-weight: 500;
	text-decoration: none;
	transition: background-color 0.2s;
	display: inline-block;
	position: relative;
}

.button-6:hover {
	background-color: #ce7305;
}

.button-6.is-inverted-color {
	color: #1a49ba;
	background-color: #fff;
	border: 1px solid #1a49ba;
	margin-right: 1rem;
	transition: border 0.2s, color 0.2s, background-color 0.2s;
}

.button-6.is-inverted-color:hover {
	color: #fff;
	background-color: #1a49ba;
	border: 1px solid #1a49ba;
}

.left-nav-wrapper {
	height: auto;
	border-top: 0 solid rgba(0, 0, 0, 0);
	border-right: 0 rgba(0, 0, 0, 0);
	margin-left: 15px;
	margin-right: 2.25vw;
	padding-top: 2vw;
	padding-bottom: 2vw;
	display: flex;
}

.button-4 {
	min-width: 100px;
	color: #fff;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	background-color: #fbb911;
	border-radius: 20px;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	margin-right: 1rem;
	padding: 7px 1.15rem 8px;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.3s;
	display: inline-block;
}

.button-4:hover {
	color: #fff;
	background-color: #1a49ba;
}

.button-4.secondary {
	background-color: #797676;
}

.button-4.secondary:hover {
	background-color: #313131;
}

.button-4.tertiary {
	min-width: 0;
	color: #333;
	background-color: #fff;
	border: 1px solid #333;
	border-radius: 20px;
	padding: 0.5rem 1.5rem;
	font-size: 14px;
	font-weight: 400;
}

.button-4.tertiary:hover {
	background-color: rgba(0, 0, 0, 0);
	border-color: #fbb911;
}

.divider-2 {
	height: 1px;
	clear: both;
	background-color: #c9c6c4;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

.heading-1 {
	color: #333;
	margin-top: 10px;
	font-size: 34px;
	font-weight: 700;
}

.main-content-wrapper {
	padding-top: 0.6rem;
	overflow: hidden;
}

.main-content-wrapper.no-sidebars {
	font-size: 1.125rem;
}

.intro-text-style {
	color: #333;
	margin-bottom: 0.75rem;
	font-size: 14px;
	line-height: 20px;
}

.container {
	width: 100%;
	max-width: 1500px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding: 10vw 4vw 3vw;
	display: flex;
}

.left-nav-nested-list-link {
	color: #313131;
	padding: 0.75rem 1rem 0.6875rem 0.5rem;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.2rem;
	text-decoration: none;
	transition: padding 0.2s, background-color 0.2s, color 0.2s;
	display: block;
}

.left-nav-nested-list-link:hover {
	color: #1a49ba;
	background-image: url('/20181016082423/assets/images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 6px;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	text-decoration: underline;
}

.left-nav-nested-list-link.w--current {
	color: #721926;
	background-image: url('/20181016082423/assets/images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 6px;
	padding-left: 0.75rem;
	padding-right: 0.4rem;
	font-weight: 600;
}

.left-nav-nested-list-link.w--current:hover {
	color: #721926;
}

.left-nav-nested-list {
	background-color: rgba(0, 0, 0, 0);
	border-top: 0 solid rgba(0, 0, 0, 0);
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 0 0 0.75em;
}

.breadcrumb-list {
	color: #313131;
	letter-spacing: 0.02rem;
	text-transform: uppercase;
	margin: 0 0.125rem 1rem;
	padding-top: 0.25em;
	padding-left: 0;
	font-size: 0.8rem;
	line-height: 1rem;
}

.breadcrumb-list-item {
	float: left;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	margin-right: 0.5rem;
}

.button-3-copy {
	color: #fff;
	text-align: center;
	background-color: #f58a07;
	border-radius: 1.9375rem;
	justify-content: center;
	align-items: center;
	padding: 0.75rem 2rem;
	font-weight: 500;
	text-decoration: none;
	transition: background-color 0.2s;
	display: inline-block;
	position: relative;
}

.button-3-copy:hover {
	background-color: #ce7305;
}

.button-3-copy.is-inverted-color {
	width: auto;
	height: auto;
	min-width: auto;
	color: #fff;
	background-color: #fbb911;
	border: 1px solid #fbb911;
	margin-top: 0;
	margin-right: 1rem;
	font-size: 16px;
	font-weight: 700;
	transition: border 0.2s, color 0.2s, background-color 0.2s;
	display: inline-block;
}

.button-3-copy.is-inverted-color:hover {
	color: #fff;
	background-color: #1a49ba;
	border: 1px solid #1a49ba;
}

.table-style-embed {
	border-radius: 0;
}

.left-nav-list-item {
	margin-bottom: 0;
	font-size: 1rem;
}

.inside-page-header-content-wrap {
	width: 100%;
	border-bottom: 1px #d5e1e6;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 0.5rem;
	margin-bottom: 1em;
	margin-left: 4%;
	padding-bottom: 0;
	display: flex;
	position: relative;
}

.inside-content-wrapper {
	margin-left: 2%;
	margin-right: 2%;
	padding-bottom: 2rem;
	display: flex;
}

.form-success {
	color: #000;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	border-radius: 50px;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
}

.paragraph {
	font-size: 14px;
	font-weight: 400;
}

.form-wrap {
	border-radius: 0;
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
}

.header-link {
	color: #721926;
	border-bottom: 1px solid #797676;
}

.header-link:hover {
	color: #797676;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.header-link.h2-link,
.header-link.h1-link,
.header-link.h3-link {
	color: #1a49ba;
}

.header-link.h4-link {
	color: #313131;
}

.header-link.h4-link:hover {
	color: #1a49ba;
}

.header-link.h5-link {
	color: #313131;
}

.header-link.h5-link:hover {
	color: #1a49ba;
}

.header-link.h6-link {
	color: #313131;
	border-bottom-width: 0;
}

.header-link.h6-link:hover {
	color: #721926;
}

.header-link.h1-link {
	color: #67011f;
	border-bottom-width: 0;
}

.header-link.h5-link {
	color: #313131;
	border-bottom-width: 0;
}

.header-link.h5-link:hover {
	color: #721926;
}

.header-link.h2-link,
.header-link.h3-link,
.header-link.h4-link {
	color: #67011f;
	border-bottom-width: 0;
}

.header-link.h4-link:hover {
	color: #721926;
}

.header-link.h4-link {
	color: #313131;
}

.header-link.h4-link:hover {
	color: #721926;
}

.button-4-copy {
	min-width: 100px;
	color: #fff;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	background-color: #721926;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	margin-right: 1rem;
	padding: 7px 1.15rem 8px;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.3s;
	display: inline-block;
}

.button-4-copy:hover {
	color: #fff;
	background-color: #313131;
}

.button-4-copy.secondary {
	background-color: #797676;
	display: none;
}

.button-4-copy.secondary:hover {
	background-color: #313131;
}

.mobile-menu-styles-block {
	background-color: #21272e;
	position: relative;
	overflow: hidden;
}

.image-36 {
	border-radius: 20px 20px 0 0;
}

.heading-15 {
	color: #333;
	margin-top: 0;
}

.nav-link-3 {
	box-shadow: none;
	color: #313131;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	background-image: url('/20181016082423/assets/images/tab-01.svg');
	background-position: 50% 125%;
	background-repeat: no-repeat;
	background-size: 50px 4px;
	flex: 1 0 auto;
	margin-left: 1px;
	margin-right: 1px;
	padding: 0.5rem;
	font-weight: 600;
	line-height: 1.375rem;
	display: inline-block;
}

.nav-link-3:hover {
	color: rgba(9, 49, 76, 0.5);
}

.nav-link-3.w--current {
	color: #6b6b6b;
	background-position: 50% 104%;
}

.nav-link-3.w--current:hover {
	color: #677179;
}

.form {
	border-radius: 0;
}

.inside-page-content-wrap-no-sidebars {
	margin-left: 0%;
	margin-right: 0%;
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
}

.breadcrumb-divider {
	float: right;
	margin-left: 0.5rem;
}

.left-nav-list-link {
	color: #313131;
	padding: 0.65rem 1rem 0.65rem 0.5rem;
	line-height: 1.3rem;
	text-decoration: none;
	transition: background-color 0.2s, color 0.2s, padding 0.2s;
	display: block;
}

.left-nav-list-link:hover {
	color: #1a49ba;
	background-image: none;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	text-decoration: underline;
}

.left-nav-list-link.w--current {
	color: #721926;
	background-image: url('/20181016082423/assets/images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 7px;
	padding-left: 0.75em;
	padding-right: 0.4rem;
	font-weight: 600;
	transition-property: none;
}

.left-nav-list-link.w--current:hover {
	color: #721926;
}

.form-error {
	color: #000;
	background-color: rgba(31, 121, 190, 0.1);
	border: 2px solid #1f79be;
	border-radius: 50px;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
	font-weight: 400;
}

.text-link {
	color: #313131;
	border-bottom: 1px solid #797676;
	line-height: 1rem;
	transition-duration: 0.2s;
}

.text-link:hover {
	color: #1a49ba;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.text-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.text-link.breadcrumb-link {
	border-bottom-color: rgba(0, 0, 0, 0);
	display: inline-block;
}

.text-link.breadcrumb-link:hover {
	color: #721926;
	border-bottom-color: #c5d7df;
}

.inside-page-header-content-wrap-2 {
	width: 100%;
	border-bottom: 1px #d5e1e6;
	margin-top: 60px;
	margin-bottom: 1em;
	padding-bottom: 0;
	position: relative;
}

.text-link-2 {
	color: #313131;
	border-bottom: 1px solid #797676;
	line-height: 1rem;
	transition-duration: 0.2s;
}

.text-link-2:hover {
	color: #721926;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.text-link-2.breadcrumb-link {
	border-bottom-color: rgba(0, 0, 0, 0);
	display: inline-block;
}

span.text-link-2.breadcrumb-link:hover {
	border-bottom-color: rgba(0, 0, 0, 0);
	color: #222;
}

.text-link-2.breadcrumb-link:hover {
	color: #67011f;
	border-bottom-color: #c5d7df;
}

.form-success-2 {
	color: #000;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
}

.two-part-button-wrap-2 {
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	position: relative;
}

.inside-page-content-wrap-no-sidebars-2 {
	margin-left: 0%;
	margin-right: 0%;
	width: 100%;
}

.left-nav-list-link-2 {
	color: #313131;
	background-image: none;
	background-position: 0 0;
	background-repeat: repeat;
	background-size: auto;
	padding: 0.65rem 1rem 0.65rem 0.5rem;
	line-height: 1.3rem;
	transition: background-color 0.2s, color 0.2s, padding 0.2s;
	display: block;
}

.left-nav-list-link-2:hover {
	color: #67011f;
	background-image: none;
	background-position: 0 0;
	background-size: auto;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.left-nav-list-link-2.w--current:hover {
	color: #721926;
}

.form-error-2 {
	color: #000;
	background-color: #ffccd0;
	border: 2px solid #721926;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
	font-weight: 400;
}

.left-nav-col-2 {
	width: 20%;
	height: 100%;
	min-width: 220px;
	background-color: rgba(0, 0, 0, 0);
	flex: none;
}

.inside-content-wrapper-2 {
	padding-bottom: 2rem;
	display: flex;
	width: 100%;
}

.left-nav-list-item-2 {
	margin-bottom: 1px;
	font-size: 1rem;
	box-shadow: 0 -1px #a2b1c4;
}

.left-nav-wrapper-2 {
	height: auto;
	border-top: 3px solid #053061;
	border-right: 1px #a2b1c4;
	margin-right: 2.25vw;
	padding-top: 0;
	padding-bottom: 4vw;
	display: block;
}

.left-nav-list-2 {
	margin-top: 0;
	margin-bottom: 1.5em;
	padding-left: 0;
	box-shadow: 0 1px #a2b1c4;
}

.nav-link-4 {
	box-shadow: none;
	color: #313131;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	background-image: url('/20181016082423/assets/images/tab-01.svg');
	background-position: 50% 125%;
	background-repeat: no-repeat;
	background-size: 50px 4px;
	flex: 1 0 auto;
	margin-left: 1px;
	margin-right: 1px;
	padding: 0.5rem;
	font-weight: 600;
	line-height: 1.375rem;
	display: inline-block;
}

.nav-link-4:hover {
	color: rgba(9, 49, 76, 0.5);
}

.nav-link-4.w--current {
	color: #6b6b6b;
	background-position: 50% 104%;
}

.nav-link-4.w--current:hover {
	color: #677179;
}

.left-nav-nested-list-item-2 {
	margin-bottom: 1px;
	box-shadow: 0 1px #a2b1c4;
}

.intro-text-style-2 {
	color: #374957;
	margin-bottom: 0.75rem;
	font-size: 1.35rem;
	line-height: 1.95rem;
}

.inside-content-column {
	width: 100%;
	margin-right: 20px;
}

.content-wrapper {
	align-items: flex-start;
	display: flex;
	width: 100%;
}

.button {
	min-width: 100px;
	color: #fff;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	background-color: #053061;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	margin-right: 1rem;
	padding: 7px 1.15rem 8px;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.3s;
	display: inline-block;
}

.button:hover {
	color: #fff;
	background-color: #166b3e;
}

.button.secondary {
	background-color: #67011f;
}

.button.secondary:hover {
	background-color: #07693a;
}

.button.tertiary {
	min-width: 0;
	color: #313131;
	background-color: #fff;
	border: 1px solid #797676;
	padding: 0.25rem 0.75rem;
	font-size: 0.9rem;
}

.button.tertiary:hover {
	background-color: #c9c6c4;
}

.button.secondary-copy {
	background-color: #797676;
	display: none;
}

.button.secondary-copy:hover {
	background-color: #313131;
}

.button.tertiary-copy {
	min-width: 0;
	color: #313131;
	background-color: #fff;
	border: 1px solid #797676;
	padding: 0.25rem 0.75rem;
	font-size: 0.9rem;
	display: none;
}

.button.tertiary-copy:hover {
	background-color: #c9c6c4;
}

.left-nav-nested-list-2 {
	background-color: rgba(95, 164, 206, 0.3);
	border-top: 1px solid rgba(5, 48, 97, 0.3);
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 0 0 0.75em;
}

.left-nav-nested-list-link-2 {
	color: #313131;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	padding: 0.75rem 1rem 0.6875rem 0.5rem;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.2rem;
	transition: padding 0.2s, background-color 0.2s, color 0.2s;
	display: block;
}

.left-nav-nested-list-link-2:hover {
	color: #67011f;
	background-image: url('/20181016082423/assets/images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 6px;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.left-nav-nested-list-link-2.w--current:hover {
	color: #721926;
}

.text-link-3 {
	color: #313131;
	border-bottom: 0 solid #797676;
	line-height: 1rem;
	transition-property: none;
}

.text-link-3:hover {
	color: #67011f;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.text-link-3.w--current {
	color: #28313b;
	font-weight: 700;
}

.form-wrap-2 {
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
}

.section {
	max-width: 1500px;
	margin-top: 10vw;
	margin-left: 5%;
	margin-right: 5%;
	font-family: Outfit, sans-serif;
}

.heading-16 {
	color: #67011f;
	margin-bottom: 20px;
}

.heading-17,
.heading-18,
.heading-19,
.heading-20 {
	color: #67011f;
}

.button-copy {
	min-width: 100px;
	color: #fff;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	background-color: #721926;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	margin-right: 1rem;
	padding: 7px 1.15rem 8px;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.3s;
	display: none;
}

.button-copy:hover {
	color: #fff;
	background-color: #313131;
}

.button-copy.secondary {
	background-color: #797676;
}

.button-copy.secondary:hover {
	background-color: #313131;
}

.button-copy.tertiary {
	min-width: 0;
	color: #313131;
	background-color: #fff;
	border: 1px solid #797676;
	padding: 0.25rem 0.75rem;
	font-size: 0.9rem;
}

.button-copy.tertiary:hover {
	background-color: #c9c6c4;
}

.heading-21,
.heading-22 {
	color: #67011f;
}

.left-nav-nested-list-2-copy {
	background-color: rgba(0, 0, 0, 0);
	border-top: 1px solid #a2b1c4;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 0 0 0.75em;
}

.heading-23 {
	color: #0a0442;
}

.heading-24 {
	color: #67011f;
}

.image-37 {
	margin-bottom: 20px;
}

.form-success-3 {
	color: #000;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
}

.left-nav-list-link-3 {
	color: #313131;
	background-image: url('/20181016082423/assets/images/icon_arrow2_right_blk_25.svg');
	background-position: -10px;
	background-repeat: no-repeat;
	background-size: 7px 10px;
	padding: 0.65rem 1rem 0.65rem 0.5rem;
	line-height: 1.3rem;
	transition: background-color 0.2s, color 0.2s, padding 0.2s;
	display: block;
}

.left-nav-list-link-3:hover {
	color: #721926;
	background-image: url('/20181016082423/assets/images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-size: 7px;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.left-nav-list-link-3.w--current {
	color: #721926;
	background-image: url('/20181016082423/assets/images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 7px;
	padding-left: 0.75em;
	padding-right: 0.4rem;
	font-weight: 600;
	transition-property: none;
}

.left-nav-list-link-3.w--current:hover {
	color: #721926;
}

.form-error-3 {
	color: #000;
	background-color: #ffccd0;
	border: 2px solid #721926;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
	font-weight: 400;
}

.inside-content-wrapper-3 {
	padding-bottom: 2rem;
	display: flex;
}

.left-nav-wrapper-3 {
	height: auto;
	border-top: 3px solid #c9c6c4;
	border-right: 1px #d5e1e6;
	margin-right: 2.25vw;
	padding-top: 0;
	padding-bottom: 4vw;
	display: block;
}

.intro-text-style-3 {
	color: #313131;
	margin-bottom: 0.75rem;
	font-size: 1.35rem;
	line-height: 1.95rem;
}

.button-7 {
	min-width: 100px;
	color: #fff;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	background-color: #721926;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	margin-right: 1rem;
	padding: 7px 1.15rem 8px;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.3s;
	display: inline-block;
}

.button-7:hover {
	color: #fff;
	background-color: #313131;
}

.button-7.secondary {
	background-color: #797676;
}

.button-7.secondary:hover {
	background-color: #313131;
}

.button-7.tertiary {
	min-width: 0;
	color: #313131;
	background-color: #fff;
	border: 1px solid #797676;
	padding: 0.25rem 0.75rem;
	font-size: 0.9rem;
}

.button-7.tertiary:hover {
	background-color: #c9c6c4;
}

.left-nav-nested-list-3 {
	background-color: rgba(0, 0, 0, 0.04);
	border-top: 1px solid #c9c6c4;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 0 0 0.75em;
}

.left-nav-nested-list-link-3 {
	color: #313131;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	padding: 0.75rem 1rem 0.6875rem 0.5rem;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.2rem;
	transition: padding 0.2s, background-color 0.2s, color 0.2s;
	display: block;
}

.left-nav-nested-list-link-3:hover {
	color: #721926;
	background-image: url('/20181016082423/assets/images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 6px;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.left-nav-nested-list-link-3.w--current {
	color: #721926;
	background-image: url('/20181016082423/assets/images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 6px;
	padding-left: 0.75rem;
	padding-right: 0.4rem;
	font-weight: 600;
}

.left-nav-nested-list-link-3.w--current:hover {
	color: #721926;
}

.text-link-4 {
	color: #313131;
	border-bottom: 1px solid #797676;
	line-height: 1rem;
	transition-duration: 0.2s;
}

.text-link-4:hover {
	color: #721926;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.text-link-4.w--current {
	color: #28313b;
	font-weight: 700;
}

.right-col-item-wrap {
	border-top: 3px solid #053061;
	margin-bottom: 1.5rem;
	margin-left: 0;
	padding-top: 0.75rem;
	position: relative;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0;
}

.right-col-link {
	color: #313131;
	border-bottom: 1px solid #797676;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.4em;
	transition-duration: 0.2s;
}

.right-col-link:hover {
	color: #721926;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.right-col-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.right-col-item-wrap-copy {
	border-top: 3px solid #c9c6c4;
	margin-bottom: 1.5rem;
	margin-left: 0;
	padding-top: 0.75rem;
	position: relative;
}

.right-col {
	width: 20%;
	margin-left: 0%;
	font-family: Outfit, sans-serif;
}

.heading-25,
.heading-26 {
	color: #67011f;
	margin-top: 10px;
}

.heading-27 {
	color: #67011f;
}

.block-quote {
	background-color: rgba(141, 198, 65, 0.1);
	border-left-color: #053061;
}

.nav-link-mobile {
	color: #053061;
	text-transform: uppercase;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Outfit, sans-serif;
	font-size: 17px;
	font-weight: 500;
	line-height: 24px;
	transition: all 0.3s;
	display: none;
}

.nav-link-mobile:hover {
	color: #67011f;
}

.nav-link-mobile.w--current {
	color: #000;
}

.div-block-133 {
	height: 250px;
	background-image: url('/20181016082423/assets/images/bg-divider.svg');
	background-position: 50% 100%;
	background-repeat: repeat-x;
	background-size: auto;
}

.link-block-copy {
	width: 15%;
	height: 200px;
	color: #383838;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: Open Sans, sans-serif;
	font-weight: 300;
	text-decoration: none;
	transition: all 0.45s;
	display: none;
}

.link-block-copy:hover {
	transform: scale(1.1);
}

@media screen and (min-width: 992px) {
	.spacer-blank-2 {
		height: auto;
		min-height: 195px;
	}
}

@media screen and (min-width: 1440px) {
	.search-icon:hover {
		background-color: #00e5e0;
	}

	.cta_content-right-left-2:hover {
		background-position: 0 0, 50%;
		background-repeat: repeat, no-repeat;
		background-size: auto, cover;
	}

	.button-5:hover {
		background-color: #166b3e;
	}

	.search-icon-2:hover {
		background-color: #00e5e0;
	}

	.text-block-39 {
		font-size: 40px;
	}

	.search-4 {
		margin-bottom: 11px;
	}
}

@media screen and (max-width: 991px) {
	.main-button {
		display: none;
	}

	.wrapper {
		justify-content: center;
	}

	.contact-us {
		transition: background-color 0.4s;
	}

	.top-nav {
		padding-left: 40px;
		padding-right: 61px;
		position: relative;
	}

	.search-bar {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		display: none;
	}

	.uui-navbar02_dropdown-content-left-2 {
		padding-left: 0;
		padding-right: 0;
	}

	.nav-link {
		width: 100%;
		color: #053061;
		padding: 0.75rem 0;
		font-size: 16px;
	}

	.nav-link:hover {
		color: #67011f;
	}

	.menu-icon_line-middle {
		width: 24px;
		height: 2px;
		background-color: #053061;
		border-radius: 1rem;
		justify-content: center;
		align-items: center;
		margin-top: 6px;
		margin-bottom: 6px;
		padding-bottom: 0;
		padding-right: 0;
		display: flex;
	}

	.search-input-exit.two {
		padding-left: 0;
		padding-right: 0;
	}

	.uui-navbar02_dropdown-content-right-2 {
		max-width: 50rem;
		margin-bottom: 1rem;
	}

	.menu-icon_component-2 {
		width: 48px;
		height: 48px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-right: -0.5rem;
		padding-bottom: 0;
		padding-right: 0;
		display: flex;
	}

	.uui-navbar02_dropdown-toggle-2 {
		color: #101828;
		align-items: center;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 0;
		font-family: Exo, sans-serif;
		font-size: 1.125rem;
		line-height: 24px;
		display: flex;
	}

	.uui-navbar02_dropdown-toggle-2:hover {
		color: #101828;
	}

	.uui-navbar02_dropdown-toggle-2.w--open {
		width: auto;
	}

	.uui-navbar02_menu-2 {
		height: 90vh;
		-webkit-text-fill-color: inherit;
		background-color: #fff;
		background-clip: border-box;
		align-items: flex-start;
		margin-left: 0;
		padding: 1.5rem 2rem 5rem;
		position: absolute;
		overflow: auto;
	}

	.dropdown-link {
		padding-left: 0;
		padding-right: 0;
	}

	.dropdown-link:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.uui-navbar02_menu-dropdown {
		width: 100%;
		background-color: rgba(0, 0, 0, 0);
		flex-direction: column;
		align-items: flex-start;
		position: relative;
	}

	.text-block-12 {
		color: #053061;
		font-size: 16px;
	}

	.text-block-12:hover {
		color: #67011f;
	}

	.brand {
		align-items: center;
		margin-right: auto;
		display: block;
	}

	.uui-navbar02_dropdown-right-overlay-absolute {
		min-width: 100%;
		border-radius: 0.5rem;
	}

	.uui-navbar02_menu-left-2 {
		grid-column-gap: 0px;
		grid-row-gap: 0.5rem;
		color: #101828;
		flex-direction: column;
		align-items: flex-start;
		display: flex;
	}

	.search-input {
		padding-top: 1.55rem;
	}

	.menu-icon_line-bottom-3 {
		width: 24px;
		height: 2px;
		background-color: #053061;
		border-radius: 1rem;
		padding-bottom: 0;
		padding-right: 0;
	}

	.login-button-main-mobile {
		background-position: 20%;
		margin-right: 10px;
		font-size: 14px;
		font-weight: 500;
		display: none;
	}

	.uui-navbar02_item-heading-2 {
		color: #053061;
		font-family: Outfit, sans-serif;
		font-size: 1rem;
		line-height: 24px;
	}

	.uui-navbar02_item-heading-2:hover {
		color: #67011f;
	}

	.nav-items-container {
		justify-content: flex-end;
	}

	.menu-icon_line-middle-inner-3 {
		width: 4px;
		height: 0;
		padding-bottom: 0;
		padding-right: 0;
	}

	.uui-navbar02_dropdown-content-2 {
		flex-direction: column;
	}

	.uui-dropdown-icon {
		color: #667085;
		justify-content: flex-start;
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 0;
		display: flex;
		top: auto;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.uui-navbar02_menu-button-3 {
		display: flex;
	}

	.uui-navbar02_menu-button-3.w--open {
		background-color: rgba(0, 0, 0, 0);
	}

	.uui-navbar02_dropdown-content-button-wrapper {
		margin-bottom: 0;
		margin-left: 0;
	}

	.uui-navbar02_dropdown-list-2 {
		position: relative;
		overflow: hidden;
	}

	.uui-navbar02_dropdown-list-2.w--open {
		width: 100%;
		min-width: auto;
		box-shadow: none;
		background-color: rgba(0, 0, 0, 0);
		border-style: none;
		align-items: flex-start;
		margin-bottom: 0;
		padding: 0 0 0;
		position: relative;
		top: 0;
		left: 0;
		right: 0;
	}

	.uui-navbar02_blog-content {
		max-width: none;
	}

	.menu-icon_line-top {
		width: 24px;
		height: 2px;
		background-color: #053061;
		border-radius: 1rem;
		padding-bottom: 0;
		padding-right: 0;
	}

	.search-icon {
		width: 40px;
		height: 40px;
		min-height: auto;
		background-size: 17px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.search-icon:hover {
		background-size: 17px;
	}

	.invisible-block {
		display: none;
	}

	.slider-container-2 {
		width: auto;
		height: 442px;
	}

	.lynx-block-hero-2 {
		width: 50%;
		border-bottom-right-radius: 230px;
		margin-left: 15%;
		padding-left: 0;
		padding-right: 0;
		top: 5%;
		left: 4%;
	}

	.icon-wrapper {
		width: 100%;
		background-color: rgba(0, 0, 0, 0);
		border-top-style: none;
		border-bottom-style: none;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		margin-top: 0;
	}

	.h3-headings {
		text-align: center;
		text-indent: 0;
		margin-top: 1rem;
		margin-bottom: 0;
		line-height: 2.25rem;
	}

	.h3-headings.icon-headings {
		margin-top: 0.25rem;
		font-size: 0.9rem;
		line-height: 1.3rem;
	}

	.main-button-apply {
		text-align: center;
		background-image: none;
		margin-top: 10px;
		margin-right: 0;
		padding-left: 25px;
	}

	.hero-div {
		height: auto;
		grid-template-columns: 1.25fr 1fr;
	}

	.bold-text-7 {
		font-size: 15px;
	}

	.quick-links-wrapper {
		width: 95%;
		align-items: center;
		margin-top: -118px;
		padding-bottom: 0;
		padding-left: 3%;
	}

	.icon-link-wrapper {
		width: 30ch;
		height: 155px;
		flex-direction: column;
		padding-top: 0;
	}

	.footer-container {
		padding-left: 0;
		padding-right: 0;
	}

	.column-3 {
		flex-direction: column;
		align-items: flex-start;
		padding-left: 57px;
		display: flex;
	}

	.columns {
		border-top-width: 0;
		padding-top: 0;
		padding-bottom: 0;
	}

	.footer-signoff-link-wrap {
		float: left;
		margin-bottom: 1.5rem;
		display: block;
	}

	.footer-copyright {
		float: left;
		margin-bottom: 0.25rem;
		margin-right: 0;
	}

	.column-2 {
		flex-direction: column;
		align-items: flex-end;
		padding-right: 10px;
	}

	.footer-signoff-links {
		margin-left: 0;
		margin-right: 0.75rem;
	}

	.heading-6 {
		font-size: 34px;
	}

	.uui-testimonial05_logo-wrapper {
		margin-bottom: 0;
	}

	.uui-container-large-11 {
		width: 90%;
	}

	.testimonias-one-by-one {
		padding-top: 2rem;
	}

	.uui-padding-vertical-xhuge-11 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.slider-2 {
		height: 350px;
		top: 100px;
	}

	.heading-7 {
		margin-top: 60px;
		font-size: 50px;
		line-height: 45px;
	}

	._1-3-grid {
		grid-row-gap: 25px;
		grid-template-columns: 1fr;
	}

	._1-3-grid._50-p {
		grid-column-gap: 8px;
		grid-row-gap: 80px;
		flex-direction: column;
		grid-template-columns: 1fr 1fr 1fr;
		display: flex;
	}

	.main-section {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.dividel-footer {
		height: 35px;
	}

	.nav-link-enquire {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-enquire:hover {
		color: #101828;
	}

	.icon-wrap-copy {
		width: 5.3rem;
		height: 3.4rem;
		width: 5.3rem;
		height: 3.4rem;
		width: 5.3rem;
		height: 3.4rem;
		margin-bottom: 0.5rem;
	}

	.button-with-arrow {
		width: 100%;
		justify-content: flex-start;
	}

	.text-cta {
		width: 100%;
		font-size: 12px;
	}

	.div-block-125 {
		width: 100%;
		background-color: #0a0442;
		padding-top: 10px;
	}

	.services-icons {
		max-width: none;
		flex-flow: wrap;
	}

	.link-block {
		width: 20%;
		height: 150px;
	}

	.lynx-heading {
		font-size: 38px;
	}

	.lynx-left-arrow {
		margin-left: 10px;
	}

	.services-slider {
		margin-top: 140px;
		padding-left: 2%;
		padding-right: 2%;
	}

	.lynx-right-arrow {
		margin-right: 10px;
		padding-right: 0;
	}

	.parallax-image-1 {
		height: 400px;
	}

	.div-block-128 {
		width: 80%;
	}

	.heading-3 {
		font-size: 45px;
		line-height: 50px;
	}

	.text-block-35 {
		font-size: 20px;
		line-height: 25px;
	}

	.div-block-5 {
		height: auto;
	}

	.heading-2 {
		font-size: 18px;
	}

	.news-section {
		padding-top: 40px;
		padding-left: 40px;
		padding-right: 40px;
	}

	.container-large.is-huge {
		max-width: none;
	}

	.padding-section-medium {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.padding-section-medium.is-bottom-0 {
		padding-bottom: 0;
	}

	.cta_content-right {
		align-items: flex-start;
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	.cta_component {
		height: auto;
		grid-template-columns: 1fr;
		display: flex;
	}

	.padding-bottom {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.team_grid {
		width: 100%;
		grid-template-columns: 1fr 1fr;
	}

	.section_team {
		margin-bottom: 0;
		overflow: hidden;
	}

	.about_team_header {
		grid-template-columns: 1fr;
	}

	.text-cta-2 {
		width: auto;
		font-size: 12px;
	}

	.button-with-arrow-copy,
	.button-with-arrow-promo {
		width: 87%;
	}

	.cta_content-right-left-2 {
		align-items: flex-start;
		padding: 2rem 2.5rem;
	}

	.section-content {
		width: 100%;
		margin-left: 0;
		padding: 3rem 3px;
		left: 0%;
	}

	.content-headings {
		text-align: center;
		text-indent: 0;
		margin-top: 0;
		margin-bottom: 0.5rem;
		line-height: 3.5rem;
	}

	.paragraph-9 {
		width: auto;
		max-width: 650px;
	}

	.div-block-129 {
		width: 100%;
		grid-column-gap: 20px;
		grid-row-gap: 20px;
		flex-direction: column;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-auto-columns: 1fr;
		align-items: start;
		margin-left: 0%;
		display: grid;
	}

	.div-block-130 {
		align-items: flex-start;
	}

	.footer-social-icon-link-2 {
		width: 1.8rem;
		height: 1.8rem;
	}

	.link-list-title-2 {
		margin-bottom: 1rem;
		font-family: Montserrat, sans-serif;
	}

	.div-block-131 {
		z-index: 115;
		height: 40px;
		background-color: #1a1a1a;
		flex-direction: row;
		padding-left: 5%;
		padding-right: 5%;
		display: none;
		position: fixed;
	}

	.login-button-main-2 {
		background-image: url('/20181016082423/assets/images/lock.svg');
		background-repeat: no-repeat;
		background-size: 20px;
		margin-right: 0;
		display: none;
	}

	.nav-link-copy {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-copy:hover {
		color: #101828;
	}

	.nav-link-copy {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-copy:hover {
		color: #101828;
	}

	.image-31 {
		padding-top: 10px;
		padding-bottom: 10px;
		bottom: auto;
	}

	.button-with-arrow-parallax {
		width: 100%;
		justify-content: center;
	}

	.text-cta-3 {
		width: auto;
		font-size: 12px;
	}

	.main-container-2 {
		flex-direction: column;
		align-items: center;
	}

	.h3-2 {
		font-size: 18px;
	}

	.mega-desktop {
		color: #1d1d1b;
		padding-top: 10px;
		padding-bottom: 10px;
		display: flex;
		top: 0;
		bottom: auto;
		left: 0%;
		right: 0%;
	}

	.menu-icon_line-middle-2 {
		width: 24px;
		height: 2px;
		background-color: #053061;
		border-radius: 1rem;
		justify-content: center;
		align-items: center;
		margin-top: 6px;
		margin-bottom: 6px;
		padding-bottom: 0;
		padding-right: 0;
		display: flex;
	}

	.brand-2 {
		align-items: center;
		margin-right: auto;
	}

	.uui-navbar02_container-2 {
		border-bottom: 1px solid #101828;
		justify-content: flex-end;
	}

	.nav-link-2 {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-2:hover {
		color: #166b3e;
	}

	.uui-navbar02_menu-dropdown-2 {
		width: 100%;
		background-color: rgba(0, 0, 0, 0);
		position: relative;
	}

	.search-icon-2 {
		width: 40px;
		height: 40px;
		min-height: auto;
		background-size: 17px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.search-icon-2:hover {
		background-size: 17px;
	}

	.mega-menu-mobile {
		color: #1d1d1b;
		display: block;
		top: 0;
		bottom: auto;
		left: 0%;
		right: 0%;
	}

	.text-block-38 {
		color: #101828;
		font-size: 1.125rem;
	}

	.text-block-38:hover {
		color: #166b3e;
	}

	.nav-link-copy-2 {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-copy-2:hover {
		color: #101828;
	}

	.uui-navbar02_dropdown-toggle-3 {
		color: #101828;
		align-items: center;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 0;
		font-family: Exo, sans-serif;
		font-size: 1.125rem;
		line-height: 24px;
		display: flex;
	}

	.uui-navbar02_dropdown-toggle-3:hover {
		color: #101828;
	}

	.image-35 {
		bottom: auto;
	}

	.uui-navbar02_item-heading-3 {
		color: #101828;
		font-family: Exo, sans-serif;
		font-size: 1rem;
		line-height: 24px;
	}

	.uui-navbar02_item-heading-3:hover {
		color: #166b3e;
	}

	.nav-link-copy-3 {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-copy-3:hover {
		color: #101828;
	}

	.uui-navbar02_menu-3 {
		height: 100vh;
		background-color: #fff;
		align-items: flex-start;
		margin-left: 0;
		padding: 1.5rem 2rem 5rem;
		position: absolute;
		overflow: auto;
	}

	.uui-navbar02_dropdown-list-3 {
		position: relative;
		overflow: hidden;
	}

	.uui-navbar02_dropdown-list-3.w--open {
		min-width: auto;
		box-shadow: none;
		background-color: rgba(0, 0, 0, 0);
		border-style: none;
		align-items: flex-start;
		margin-bottom: 0;
		padding: 0 0 0;
		position: relative;
		top: 0;
		left: 0;
		right: 0;
	}

	.uui-dropdown-icon-2 {
		color: #667085;
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 0;
		top: auto;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.nav-link-enquire-2 {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-enquire-2:hover {
		color: #101828;
	}

	.uui-navbar02_menu-left-3 {
		grid-column-gap: 0px;
		grid-row-gap: 0.5rem;
		color: #101828;
		flex-direction: column;
		align-items: flex-start;
		display: flex;
	}

	.uui-navbar02_menu-button-4 {
		display: flex;
	}

	.uui-navbar02_menu-button-4.w--open {
		background-color: rgba(0, 0, 0, 0);
	}

	.image-31-copy {
		padding-top: 10px;
		padding-bottom: 10px;
		bottom: auto;
	}

	.tab-pane-established-nationally {
		padding-top: 60px;
	}

	.two-part-button-wrap {
		width: 100%;
		max-width: none;
		float: left;
		flex-wrap: nowrap;
		justify-content: space-between;
		display: none;
		position: static;
	}

	.left-nav-col {
		display: none;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.left-nav-wrapper {
		height: 0;
		overflow: hidden;
	}

	.main-content-wrapper {
		width: 100%;
		padding-top: 0;
	}

	.container {
		padding-top: 14vw;
	}

	.two-part-drop-list-back-arrow {
		min-width: 0.75em;
		float: left;
		color: rgba(0, 0, 0, 0);
		text-align: center;
		background-image: url('/20181016082423/assets/images/icon_arrow2_left_wht_50.svg');
		background-position: 0 55%;
		background-repeat: no-repeat;
		background-size: 12px 12px;
		margin-top: -1px;
		margin-right: 0.25em;
		display: inline-block;
	}

	.button-3-copy.is-inverted-color {
		margin-top: 10px;
	}

	.inside-content-wrapper {
		padding-bottom: 1rem;
	}

	.heading-15 {
		font-size: 18px;
	}

	.nav-link-3 {
		z-index: 140;
		width: 100%;
		float: left;
		color: #fff;
		text-align: left;
		letter-spacing: 0.02em;
		text-transform: none;
		margin-bottom: 1px;
		margin-right: 0;
		padding: 1.25em 1.375rem;
		font-size: 1.125rem;
		font-weight: 400;
		display: none;
		box-shadow: 0 1px rgba(255, 255, 255, 0.25);
	}

	.nav-link-3:hover {
		color: #c9c6c4;
		background-color: rgba(0, 0, 0, 0);
		box-shadow: 0 1px rgba(255, 255, 255, 0.25);
	}

	.nav-link-3.w--current {
		color: #fff;
		text-align: left;
		background-color: rgba(0, 0, 0, 0.3);
		background-image: none;
		box-shadow: 0 1px rgba(255, 255, 255, 0.25), inset 5px 0 #721926;
	}

	.nav-link-3.w--current:hover {
		color: rgba(255, 255, 255, 0.75);
		background-image: none;
	}

	.nav-link-3.two-part-mobile {
		width: 79.75%;
		float: left;
		background-image: none;
	}

	.nav-link-3.two-part-mobile:hover {
		color: rgba(255, 255, 255, 0.75);
	}

	.nav-link-3.mobile-list-back {
		color: #fff;
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.25);
		transition: all 0.35s;
	}

	.nav-link-3.mobile-list-back:hover {
		color: #fff;
	}

	.two-part-button-list {
		z-index: 150;
		width: 100%;
		background-color: #494848;
		border-top: 1px #21272e;
		margin-left: 100%;
		padding-bottom: 100vh;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-left: 0%;
		margin-right: 0%;
	}

	.two-part-button-toggle {
		width: 20%;
		float: right;
		color: rgba(0, 0, 0, 0);
		text-align: center;
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('/20181016082423/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: 1em 1em;
		flex-shrink: 0;
		margin-bottom: 1px;
		padding-top: 1.25em;
		padding-bottom: 1.25em;
		font-size: 0.875rem;
		line-height: 2rem;
		transition: all 0.35s;
		display: none;
		box-shadow: 0 1px rgba(255, 255, 255, 0.25), -1px 0 rgba(255, 255, 255, 0.25);
	}

	.two-part-button-toggle:hover {
		background-color: rgba(0, 0, 0, 0.15);
	}

	.two-part-button-toggle-2 {
		width: 20%;
		float: right;
		color: rgba(0, 0, 0, 0);
		text-align: center;
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('/20181016082423/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: 1em 1em;
		flex-shrink: 0;
		margin-bottom: 1px;
		padding-top: 1.25em;
		padding-bottom: 1.25em;
		font-size: 0.875rem;
		line-height: 2rem;
		transition: all 0.35s;
		box-shadow: 0 1px rgba(255, 255, 255, 0.25), -1px 0 rgba(255, 255, 255, 0.25);
	}

	.two-part-button-toggle-2:hover {
		background-color: rgba(0, 0, 0, 0.15);
	}

	.two-part-button-wrap-2 {
		width: 100%;
		max-width: none;
		float: left;
		flex-wrap: nowrap;
		justify-content: space-between;
		display: flex;
		position: static;
	}

	.inside-page-content-wrap-no-sidebars-2 {
		margin-left: 0%;
		margin-right: 0%;
	}

	.left-nav-col-2 {
		display: none;
	}

	.inside-content-wrapper-2 {
		padding-bottom: 1rem;
	}

	.left-nav-wrapper-2 {
		height: 0;
		overflow: hidden;
	}

	.left-nav-list-2 {
		margin-bottom: 1.5em;
	}

	.nav-link-4 {
		z-index: 140;
		width: 100%;
		float: left;
		color: #fff;
		text-align: left;
		letter-spacing: 0.02em;
		text-transform: none;
		margin-bottom: 1px;
		margin-right: 0;
		padding: 1.25em 1.375rem;
		font-size: 1.125rem;
		font-weight: 400;
		display: block;
		box-shadow: 0 1px rgba(255, 255, 255, 0.25);
	}

	.nav-link-4:hover {
		color: #c9c6c4;
		background-color: rgba(0, 0, 0, 0);
		box-shadow: 0 1px rgba(255, 255, 255, 0.25);
	}

	.nav-link-4.w--current {
		color: #fff;
		text-align: left;
		background-color: rgba(0, 0, 0, 0.3);
		background-image: none;
		box-shadow: 0 1px rgba(255, 255, 255, 0.25), inset 5px 0 #721926;
	}

	.nav-link-4.w--current:hover {
		color: rgba(255, 255, 255, 0.75);
		background-image: none;
	}

	.nav-link-4.mobile-list-back {
		color: #fff;
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.25);
		transition: all 0.35s;
	}

	.nav-link-4.mobile-list-back:hover {
		color: #fff;
	}

	.nav-link-4.two-part-mobile {
		width: 79.75%;
		float: left;
		background-image: none;
	}

	.nav-link-4.two-part-mobile:hover {
		color: rgba(255, 255, 255, 0.75);
	}

	.content-wrapper {
		flex-direction: column;
		position: relative;
	}

	.heading-16 {
		font-size: 30px;
	}

	.heading-17 {
		font-size: 30px;
		line-height: 34px;
	}

	.heading-18 {
		font-size: 28px;
	}

	.two-part-button-toggle-3 {
		width: 20%;
		float: right;
		color: rgba(0, 0, 0, 0);
		text-align: center;
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('/20181016082423/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: 1em 1em;
		flex-shrink: 0;
		margin-bottom: 1px;
		padding-top: 1.25em;
		padding-bottom: 1.25em;
		font-size: 0.875rem;
		line-height: 2rem;
		transition: all 0.35s;
		box-shadow: 0 1px rgba(255, 255, 255, 0.25), -1px 0 rgba(255, 255, 255, 0.25);
	}

	.two-part-button-toggle-3:hover {
		background-color: rgba(0, 0, 0, 0.15);
	}

	.inside-content-wrapper-3 {
		padding-bottom: 1rem;
	}

	.left-nav-wrapper-3 {
		height: 0;
		overflow: hidden;
	}

	.right-col-item-wrap,
	.right-col-item-wrap-copy,
	.right-col-item-wrap-copy,
	.right-col-item-wrap-copy {
		margin-left: 0;
	}

	.heading-25 {
		font-size: 28px;
	}

	.nav-link-mobile {
		width: 100%;
		color: #053061;
		padding: 0.75rem 0;
		font-size: 16px;
		display: flex;
	}

	.nav-link-mobile:hover {
		color: #67011f;
	}

	.link-block-copy {
		width: 20%;
		height: 150px;
	}
}

@media screen and (max-width: 767px) {
	.main-button {
		z-index: 460;
		background-color: rgba(0, 0, 0, 0);
		margin-top: -1px;
		margin-bottom: 1px;
		margin-right: 50px;
		position: relative;
		top: auto;
		bottom: 0%;
		left: 0%;
		right: 0%;
	}

	.location-link {
		margin-right: 0;
		padding-right: 0;
	}

	.wrapper {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		justify-content: center;
	}

	.contact-us {
		margin-right: 0;
		padding-right: 0;
	}

	.top-nav {
		padding-left: 10px;
		padding-right: 10px;
		position: relative;
	}

	.search-bar {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.uui-button-row {
		align-self: stretch;
	}

	.uui-button-link-gray-2 {
		font-size: 1rem;
	}

	.uui-button-link-gray-2:hover {
		color: #475467;
	}

	.uui-navbar02_dropdown-content-left-2 {
		grid-row-gap: 1.5rem;
		flex-direction: column;
		grid-template-rows: auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.nav-link {
		font-size: 1rem;
	}

	.uui-navbar02_blog-item {
		padding: 0;
	}

	.uui-navbar02_blog-item:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.uui-button-link {
		font-size: 1rem;
	}

	.uui-button-link:hover {
		color: #6941c6;
	}

	.uui-navbar02_dropdown-content-right-2 {
		padding: 2rem 1rem;
	}

	.uui-navbar02_dropdown-toggle-2 {
		font-size: 1rem;
	}

	.uui-navbar02_menu-2 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.login-button-main-mobile {
		font-size: 12px;
		display: none;
	}

	.uui-navbar02_dropdown-link-list {
		max-width: none;
		grid-row-gap: 0.5rem;
	}

	.search-icon {
		width: 40px;
		height: 40px;
		background-size: 17px;
	}

	.slider-container-2 {
		width: 100%;
		text-align: center;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		display: flex;
	}

	.lynx-block-hero-2 {
		width: 90%;
		align-items: center;
		margin-top: 30px;
		margin-left: 0;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		position: static;
		top: 0;
	}

	.icon-wrapper {
		width: 90%;
		grid-column-gap: 16px;
		flex-direction: row;
		justify-content: space-between;
		overflow: hidden;
	}

	.h3-headings {
		margin-top: 0;
		margin-bottom: 0.5rem;
	}

	.h3-headings.icon-headings {
		margin-top: 0;
		margin-bottom: 0.5rem;
		font-size: 0.8rem;
		line-height: 1.1rem;
	}

	.main-button-apply {
		font-size: 12px;
		display: block;
	}

	.main-button-apply.dark-background {
		display: block;
	}

	.hero-div {
		flex-direction: column;
		grid-template-columns: 1.25fr;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.quick-links-wrapper {
		width: 100%;
		flex-direction: row;
		justify-content: center;
		margin-top: -155px;
		padding-bottom: 40px;
		position: static;
		top: 0;
	}

	.icon-link-wrapper {
		width: 25ch;
		flex-direction: column;
		margin-bottom: 0;
		padding-left: 0.6rem;
		padding-right: 0.6rem;
	}

	.footer-container {
		padding-top: 0;
		position: relative;
	}

	.column-3 {
		align-items: center;
		overflow: hidden;
	}

	.columns {
		flex-wrap: wrap;
	}

	.footer-signoff-link-wrap {
		float: none;
		display: inline-block;
	}

	.footer-copyright {
		float: none;
	}

	.column-2 {
		flex-direction: row;
		justify-content: center;
		margin-bottom: 10px;
		padding-right: 0;
	}

	.uui-page-padding-11 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.uui-testimonial05_arrow:hover {
		background-color: #fff;
	}

	.uui-heading-medium-8 {
		font-size: 1.75rem;
		line-height: 1.4;
	}

	.uui-testimonial05_component {
		padding-bottom: 3rem;
	}

	.uui-testimonial05_slide {
		padding-left: 0;
		padding-right: 0;
	}

	.uui-padding-vertical-xhuge-11 {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.content-wrap-stats {
		align-items: center;
	}

	._1-3-grid._50-p {
		grid-template-columns: 1fr;
		justify-items: center;
	}

	.nav-link-enquire {
		font-size: 1rem;
	}

	.icon-wrap-copy {
		width: 5.3rem;
		height: 4.5rem;
		width: 5.3rem;
		height: 4.5rem;
		width: 5.3rem;
		height: 4.5rem;
		margin-bottom: 0;
	}

	.services-icons {
		z-index: 500;
		grid-column-gap: 16px;
		grid-row-gap: 40px;
		background-color: #fff;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		justify-items: center;
		display: grid;
		position: relative;
	}

	.link-block {
		width: 50%;
	}

	.lynx-slider {
		height: 620px;
	}

	.lynx-heading {
		max-width: 540px;
		font-size: 34px;
	}

	.lynx-block-slide-reviews {
		flex-direction: column;
	}

	.lynx-left-arrow {
		top: auto;
		bottom: -5%;
		left: 7%;
		right: auto;
	}

	.lynx-right-arrow {
		top: auto;
		bottom: -5%;
		left: auto;
		right: 7%;
	}

	.lynx-block-slide {
		width: 100%;
		height: 100%;
		padding: 40px 10%;
	}

	.lynx-image-slide {
		width: 100%;
		height: 250px;
		border-top-right-radius: 30px;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.parallax-image-1 {
		z-index: 500;
		padding-top: 20px;
		padding-bottom: 20px;
		position: relative;
	}

	.heading-3 {
		font-size: 40px;
		line-height: 45px;
	}

	.text-block-35 {
		font-size: 15px;
		line-height: 20px;
	}

	.div-block-6 {
		margin-bottom: 0;
	}

	.div-block-2 {
		width: 80%;
	}

	.news-section {
		z-index: 500;
		background-color: #fff;
		flex-wrap: wrap;
		padding-top: 20px;
		padding-bottom: 20px;
		position: relative;
	}

	.div-block-12 {
		flex-wrap: wrap;
		justify-content: center;
	}

	.features_grid {
		grid-template-columns: 1fr;
	}

	.features_header {
		grid-row-gap: 2rem;
		grid-template-columns: 1fr;
	}

	.padding-section-medium {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.features_components {
		grid-row-gap: 2rem;
	}

	.padding-global {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.cta_content-right {
		padding: 3.5rem 1.25rem;
	}

	.cta_component {
		grid-column-gap: 0rem;
		grid-row-gap: 0rem;
		grid-template-rows: auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		display: grid;
	}

	.padding-bottom {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.team_grid {
		width: 60%;
		flex-direction: column;
		grid-template-columns: 1fr;
	}

	.team_image {
		height: 28rem;
	}

	.about_team_component {
		align-items: center;
	}

	.team_name {
		font-size: 1.25rem;
	}

	.cta_content-right-left-2 {
		padding: 3.5rem 1.25rem;
	}

	.section-content {
		width: 100%;
		height: auto;
		padding: 2rem;
	}

	.content-headings {
		margin-top: 0;
		margin-bottom: 0.5rem;
		font-size: 40px;
		line-height: 2.5rem;
	}

	.div-block-129 {
		flex-wrap: wrap;
		justify-content: center;
	}

	.div-block-130 {
		flex-direction: column;
		justify-content: center;
	}

	.footer-social-icon-link-2 {
		margin-left: 0;
		margin-right: 1rem;
	}

	.link-text-3 {
		font-size: 0.85rem;
	}

	.div-block-131 {
		margin-bottom: 0;
		padding-top: 10px;
		padding-bottom: 10px;
		position: fixed;
	}

	.login-button-main-2 {
		font-size: 12px;
		display: none;
	}

	.search-3 {
		z-index: 460;
		margin-top: 20px;
		position: relative;
	}

	.nav-link-copy {
		font-size: 1rem;
	}

	.heading-13 {
		font-size: 40px;
		line-height: 40px;
	}

	.team_content-2 {
		right: 15%;
	}

	.team_name-copy-2 {
		font-size: 1.25rem;
	}

	.footer-grip-link-2.white-logo {
		width: 133px;
	}

	.mega-desktop {
		min-height: 4.5rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.nav-link-2 {
		font-size: 1rem;
	}

	.search-icon-2 {
		width: 40px;
		height: 40px;
		background-size: 17px;
	}

	.mega-menu-mobile {
		min-height: 4.5rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.nav-link-copy-2,
	.uui-navbar02_dropdown-toggle-3,
	.nav-link-copy-3 {
		font-size: 1rem;
	}

	.uui-navbar02_menu-3 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.nav-link-enquire-2 {
		font-size: 1rem;
	}

	.main-content-wrapper {
		padding-top: 0;
		overflow: visible;
	}

	.main-content-wrapper.no-sidebars {
		margin-left: 10px;
		margin-right: 10px;
	}

	.container {
		margin-top: 90px;
		padding-top: 4vw;
	}

	.breadcrumb-list {
		font-size: 0.6875rem;
	}

	.nav-link-3 {
		font-size: 1rem;
	}

	.nav-link-3.two-part-mobile {
		width: 79.75%;
		box-shadow: 0 1px rgba(255, 255, 255, 0.15);
	}

	.two-part-button-toggle,
	.two-part-button-toggle-2 {
		background-color: rgba(0, 0, 0, 0.25);
		line-height: 1.6875rem;
		box-shadow: 0 1px rgba(255, 255, 255, 0.15), -1px 0 rgba(255, 255, 255, 0.15);
	}

	.nav-link-4 {
		font-size: 1rem;
	}

	.nav-link-4.two-part-mobile {
		width: 79.75%;
		box-shadow: 0 1px rgba(255, 255, 255, 0.15);
	}

	.two-part-button-toggle-3 {
		background-color: rgba(0, 0, 0, 0.25);
		line-height: 1.6875rem;
		box-shadow: 0 1px rgba(255, 255, 255, 0.15), -1px 0 rgba(255, 255, 255, 0.15);
	}

	.right-col-item-wrap,
	.right-col-item-wrap-copy,
	.right-col-item-wrap-copy,
	.right-col-item-wrap-copy {
		margin-left: 0;
	}

	.nav-link-mobile {
		font-size: 1rem;
	}

	.link-block-copy {
		width: 50%;
	}
}

@media screen and (max-width: 479px) {
	.footer-links-list {
		text-align: center;
	}

	.main-section {
		margin-top: 60px;
		margin-bottom: 0px;
	}

	.content-wrap-stats-2 {
		margin-bottom: 30px !important;
	}

	._1-3-grid._50-p {
		grid-column-gap: 0;
		grid-row-gap: 0;
	}

	.tab-pane-established-nationally,
	.tab-pane-growing-in-iowa {
		padding-bottom: 0px;
	}

	.search-button {
		width: 30px;
		height: 30px;
	}

	.main-button {
		margin-top: 0;
		margin-bottom: 5px;
		margin-right: 0;
		display: none;
	}

	.location-link {
		justify-content: center;
		margin-right: 0;
		padding-right: 0;
	}

	.contact-us {
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.top-nav {
		padding-top: 10px;
		padding-bottom: 0;
	}

	.search-bar {
		width: 90%;
		top: 81px;
	}

	.uui-navbar02_dropdown-content-left-2 {
		grid-template-columns: 1fr;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.uui-navbar02_blog-item {
		flex-direction: column;
		display: flex;
	}

	.search {
		margin-right: 0.5rem;
	}

	.uui-navbar02_dropdown-content-right-2 {
		max-width: none;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.menu-icon_component-2 {
		margin-right: -0.9rem;
	}

	.dropdown-link {
		padding-left: 0;
		font-family: Outfit, sans-serif;
	}

	.text-block-12 {
		font-size: 1rem;
	}

	.uui-navbar02_dropdown-content-wrapper {
		width: 100%;
	}

	.login-button-main-mobile {
		margin-top: 0;
		margin-right: 0;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 35px;
		font-size: 11px;
		display: none;
	}

	.uui-navbar02_item-heading-2 {
		color: #053061;
		font-family: Outfit, sans-serif;
		font-size: 1rem;
	}

	.uui-navbar02_item-heading-2:hover {
		color: #67011f;
	}

	.uui-navbar02_menu-button-3.w--open {
		z-index: 50;
	}

	.uui-navbar02_dropdown-list-2.w--open {
		background-color: rgba(0, 0, 0, 0);
		margin-bottom: 0;
	}

	.uui-navbar02_blog-content {
		margin-top: 0.5rem;
	}

	.uui-navbar02_dropdown-blog-item-wrapper {
		grid-column-gap: 0px;
		grid-row-gap: 0.5rem;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
	}

	.search-icon {
		width: 35px;
		height: 35px;
		background-size: 13px;
		margin-left: 10px;
	}

	.slider-container-2 {
		height: auto;
	}

	.lynx-block-hero-2 {
		width: 90%;
		text-align: center;
		border-bottom-right-radius: 30px;
		justify-content: center;
		align-items: center;
		margin-left: 0;
		position: static;
		top: 0;
	}

	.icon-wrapper {
		width: 100%;
		max-width: none;
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		flex-direction: row;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr 1fr;
		grid-auto-columns: 1fr;
		justify-content: center;
		align-items: start;
		justify-items: center;
		margin-top: 1.4rem;
		display: grid;
	}

	.image {
		width: 0.65rem;
		height: 00.65rem;
	}

	.h3-headings {
		font-size: 0.9rem;
		line-height: 2.2rem;
	}

	.h3-headings.icon-headings {
		letter-spacing: 0;
		font-size: 0.9rem;
	}

	.main-button-apply {
		background-image: none;
		margin-top: 10px;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-size: 11px;
		display: block;
	}

	.main-button-apply.dark-background {
		width: auto;
		padding-left: 15px;
		padding-right: 15px;
		display: none;
		position: relative;
	}

	.hero-div {
		grid-template-columns: 1.25fr;
	}

	.quick-links-wrapper {
		flex-direction: row;
		margin-top: 0;
		padding-left: 0%;
		display: none;
	}

	.icon-img {
		display: block;
	}

	.icon-link-wrapper {
		width: auto;
	}

	.footer-container {
		z-index: 0;
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
		top: 0;
	}

	.column-3 {
		margin-bottom: 0;
		padding-left: 5px;
		padding-right: 5px;
	}

	.columns {
		flex-wrap: wrap;
	}

	.footer-signoff-link-wrap {
		text-align: center;
		padding-top: 10px;
	}

	.footer-copyright {
		text-align: center;
		margin-bottom: 0.5rem;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 0.9rem;
	}

	.column-2 {
		flex-direction: column;
		align-items: center;
		padding-left: 0;
	}

	.heading-6 {
		font-size: 28px;
	}

	.uui-heading-medium-8.text-weight-medium {
		font-size: 14px;
		line-height: 1.9;
	}

	.uui-testimonial05_logo-wrapper {
		margin-bottom: 0;
	}

	.uui-testimonial05_content {
		width: 98%;
	}

	.uui-padding-vertical-xhuge-11 {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.slider-2 {
		top: 76px;
	}

	.heading-7 {
		margin-top: 132px;
		font-size: 40px;
	}

	.promo-counter {
		margin-top: 73px;
	}

	.content-wrap-stats {
		grid-row-gap: 6px;
	}

	._1-3-grid._50-p {
		grid-template-columns: 1fr;
	}

	._2-images-wrapper {
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
	}

	.icon-wrap-copy {
		width: 5.7rem;
		height: 4.7rem;
		width: 5.7rem;
		height: 4.7rem;
		width: 5.7rem;
		height: 4.7rem;
		align-items: flex-end;
	}

	.left-arrow-5,
	.right-arrow-5 {
		display: none;
	}

	.services-icons {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		justify-content: space-around;
		justify-items: center;
		padding-top: 20px;
		padding-bottom: 20px;
		display: grid;
	}

	.link-block {
		width: 80%;
	}

	.service-icon {
		width: auto;
		max-width: 75px;
	}

	.service-title.acount {
		font-size: 17px;
	}

	.lynx-slider {
		height: 510px;
	}

	.lynx-heading {
		font-size: 24px;
	}

	.lynx-block-slide-reviews {
		height: auto;
		flex-direction: column;
		justify-content: space-around;
	}

	.lynx-left-arrow {
		margin-left: 60px;
		padding-right: 10px;
	}

	.lynx-heading-client {
		font-size: 16px;
	}

	.services-slider {
		padding-top: 40px;
	}

	.lynx-right-arrow {
		margin-left: 10px;
		margin-right: 60px;
	}

	.lynx-block-slide {
		height: auto;
		justify-content: flex-start;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.lynx-image-slide {
		height: auto;
	}

	.heading-3 {
		font-size: 25px;
		line-height: 30px;
	}

	.text-block-35 {
		font-size: 15px;
		line-height: 20px;
	}

	.button-no-outline {
		font-size: 12px;
	}

	.div-block-5 {
		border-radius: 0;
	}

	.heading-2 {
		margin-top: 20px;
		margin-left: 20px;
		margin-right: 20px;
		font-size: 20px;
		line-height: 25px;
	}

	.text-block-3,
	.div-block-6 {
		margin-left: 20px;
		margin-right: 20px;
	}

	.div-block-2 {
		width: 100%;
		margin-bottom: 20px;
	}

	.news-section {
		margin-top: 60px;
		padding: 0;
	}

	.div-block-12 {
		width: 90%;
	}

	.image-3 {
		border-radius: 0;
	}

	.cta_component {
		grid-column-gap: 0rem;
		grid-row-gap: 0rem;
		grid-template-rows: auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		display: grid;
	}

	.padding-bottom {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.team_grid {
		width: 90%;
	}

	.heading-12 {
		margin-bottom: 0;
		line-height: 45px;
	}

	.home-content-section.banner {
		margin-top: 75px;
	}

	.section-content {
		width: 100%;
		height: auto;
		padding: 2rem 1.5rem;
		position: static;
	}

	.content-headings {
		font-size: 2rem;
		line-height: 2.2rem;
	}

	.paragraph-9 {
		font-size: 1rem;
		line-height: 1.6rem;
	}

	.button-5 {
		margin-bottom: 1rem;
	}

	.uui-logo_component-6 {
		height: 3rem;
	}

	.footer_links-col-5 {
		align-items: center;
	}

	.div-block-129 {
		grid-template-columns: 1fr;
	}

	.footer_links-col-first,
	.footer_links-col-6 {
		align-items: center;
	}

	.text-block-37 {
		text-align: center;
	}

	.link-list-title-2 {
		justify-content: center;
	}

	.link-text-3 {
		opacity: 1;
		font-size: 0.85rem;
		line-height: 1rem;
	}

	.div-block-131 {
		justify-content: space-between;
		padding-top: 10px;
		padding-bottom: 10px;
		display: none;
	}

	.login-button-main-2 {
		width: 136px;
		background-size: 15px;
		margin-top: 8px;
		padding: 5px 15px 5px 35px;
		font-size: 11px;
		display: block;
		position: absolute;
		top: 0%;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.search-input-3 {
		height: 30px;
		margin-right: -30px;
	}

	.search-3 {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		justify-content: center;
		margin-top: 0;
		margin-bottom: 0;
		display: none;
	}

	.team_item_promo {
		width: 100%;
	}

	.two-box-promo {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		display: grid;
	}

	.counter--2,
	.counter-2-2 {
		font-size: 60px;
	}

	.spacer-blank-2 {
		width: auto;
		height: auto;
	}

	.main-container-2 {
		max-width: none;
		align-items: center;
	}

	.content-wrap-stats-2 {
		grid-row-gap: 6px;
	}

	.h3-2 {
		text-align: center;
		font-size: 16px;
	}

	.footer-grip-link-2.white-logo {
		margin-bottom: 10px;
	}

	.mega-desktop {
		min-height: 4.8rem;
		top: 0;
	}

	.uui-navbar02_container-2 {
		border-top: 1px solid #101828;
		border-bottom: 1px solid #101828;
	}

	.search-icon-2 {
		width: 35px;
		height: 35px;
		background-size: 13px;
		margin-left: 10px;
	}

	.mega-menu-mobile {
		min-height: 4.8rem;
		top: 0;
	}

	.text-block-38 {
		font-size: 1rem;
	}

	.uui-navbar02_item-heading-3 {
		color: #101828;
		font-family: Exo, sans-serif;
		font-size: 1rem;
	}

	.uui-navbar02_item-heading-3:hover {
		color: #166b3e;
	}

	.uui-navbar02_dropdown-list-3.w--open {
		background-color: rgba(0, 0, 0, 0);
		margin-bottom: 0;
	}

	.search-4 {
		margin-right: 0.5rem;
	}

	.bold-text-11 {
		font-size: 60px;
	}

	.heading-1 {
		margin-top: 15px;
		padding-right: 20px;
	}

	.main-content-wrapper.no-sidebars {
		width: auto;
		margin-left: 0;
		margin-right: 0;
	}

	.container {
		width: auto;
		max-width: none;
		align-items: stretch;
		margin-top: 100px;
		padding-left: 2%;
		padding-right: 2%;
	}

	.inside-page-header-content-wrap {
		margin-left: 0;
	}

	.inside-content-wrapper {
		margin-left: auto;
		margin-right: auto;
	}

	.nav-link-3 {
		font-size: 0.9375rem;
	}

	.nav-link-3.two-part-mobile {
		width: 79%;
	}

	.inside-page-content-wrap-no-sidebars {
		flex-direction: column;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	.two-part-button-toggle,
	.two-part-button-toggle-2 {
		font-size: 0.8125rem;
	}

	.inside-content-wrapper-2 {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		display: grid;
	}

	.nav-link-4 {
		font-size: 0.9375rem;
	}

	.nav-link-4.two-part-mobile {
		width: 79%;
	}

	.heading-16 {
		line-height: 30px;
	}

	.heading-17 {
		margin-top: 10px;
	}

	.two-part-button-toggle-3 {
		font-size: 0.8125rem;
	}

	.right-col,
	.link-block-copy {
		width: 80%;
	}
}

#w-node-_85fce01b-bc10-3c39-4608-6bd217a768d3-f5a2e5b9 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

.w-node-badb845f-bf59-ad36-6343-301bea5d9cad-f5a2e5b9,
#w-node-d01f6c32-98e4-15bc-bf0e-a12b540c1b17-f5a2e5b9 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	align-self: center;
	justify-self: center;
}

#w-node-_84654281-f345-4693-6270-b0135edbc514-f5a2e5b9 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_84654281-f345-4693-6270-b0135edbc519-f5a2e5b9 {
	grid-area: span 2 / span 2 / span 2 / span 2;
	align-self: center;
	justify-self: start;
}

#w-node-_84654281-f345-4693-6270-b0135edbc51c-f5a2e5b9 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_84654281-f345-4693-6270-b0135edbc51d-f5a2e5b9 {
	align-self: center;
}

#w-node-_84654281-f345-4693-6270-b0135edbc521-f5a2e5b9 {
	grid-area: span 2 / span 2 / span 2 / span 2;
	align-self: center;
	justify-self: start;
}

#w-node-_84654281-f345-4693-6270-b0135edbc524-f5a2e5b9 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: center;
}

#w-node-_84654281-f345-4693-6270-b0135edbc525-f5a2e5b9 {
	align-self: center;
}

#w-node-_84654281-f345-4693-6270-b0135edbc52d-f5a2e5b9 {
	grid-area: span 2 / span 2 / span 2 / span 2;
	align-self: center;
	justify-self: start;
}

#w-node-cc4265d0-7af8-2f3f-8dd3-0e13325b2aa6-f5a2e5b9 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: start;
}

#w-node-cc4265d0-7af8-2f3f-8dd3-0e13325b2aa7-f5a2e5b9 {
	align-self: center;
	justify-self: start;
}

#w-node-cc4265d0-7af8-2f3f-8dd3-0e13325b2aab-f5a2e5b9 {
	grid-area: span 2 / span 2 / span 2 / span 2;
	align-self: center;
	justify-self: start;
}

#w-node-cc4265d0-7af8-2f3f-8dd3-0e13325b2aae-f5a2e5b9 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cc4265d0-7af8-2f3f-8dd3-0e13325b2ab3-f5a2e5b9 {
	grid-area: span 2 / span 2 / span 2 / span 2;
	align-self: center;
	justify-self: start;
}

#w-node-cc4265d0-7af8-2f3f-8dd3-0e13325b2ab6-f5a2e5b9 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: center;
}

#w-node-cc4265d0-7af8-2f3f-8dd3-0e13325b2ab7-f5a2e5b9 {
	align-self: center;
}

#w-node-cc4265d0-7af8-2f3f-8dd3-0e13325b2abf-f5a2e5b9 {
	grid-area: span 2 / span 2 / span 2 / span 2;
	align-self: center;
	justify-self: start;
}

#w-node-_562c9c9b-6ada-c725-ee26-642dbdc258b9-bdc258ab {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 767px) {
	.w-node-badb845f-bf59-ad36-6343-301bea5d9c9a-f5a2e5b9,
	#w-node-d01f6c32-98e4-15bc-bf0e-a12b540c1b04-f5a2e5b9 {
		grid-area: span 1 / span 1 / span 1 / span 1;
		justify-self: center;
	}
}

@media screen and (max-width: 479px) {
	#w-node-_79ef5188-2a74-7be7-5352-b841095ab2d0-095ab2c6,
	#w-node-_79ef5188-2a74-7be7-5352-b841095ab2de-095ab2c6,
	#w-node-_79ef5188-2a74-7be7-5352-b841095ab2ef-095ab2c6,
	#w-node-_79ef5188-2a74-7be7-5352-b841095ab301-095ab2c6 {
		justify-self: center;
	}
}

/***************** Webflow ***********************/
/*************************************************/
@media (max-width: 991px) and (min-width: 768px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="85fce01b-bc10-3c39-4608-6bd217a768cf"] {
		height: 0px;
	}
}

@media (max-width: 767px) and (min-width: 480px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="85fce01b-bc10-3c39-4608-6bd217a768cf"] {
		height: 0px;
	}
}

@media (max-width: 479px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="85fce01b-bc10-3c39-4608-6bd217a768cf"] {
		height: 0px;
	}
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/****************** Header **********************/
/*****************************************************/
.logo-link {
	width: 181px;
}

@media (min-width: 992px) {
	.sticky .mega-desktop {
		padding-top: 0;
	}

	.desktop-logo {
		margin-top: 1px;
		transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
		transform-style: preserve-3d;
		transition: all 400ms ease;
	}

	.sticky .mega-desktop .desktop-logo {
		transform: translate3d(0px, 0px, 0px) scale3d(0.9, 0.9, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
		transform-style: preserve-3d;
		margin-top: -1px;
	}

	.sticky .mega-desktop .accesible-navigation-menu ul li a.nav-link {
		padding: 0.6rem 0.5rem 0.7rem 0.5rem;
	}

	.sticky .mega-desktop .accesible-navigation-menu ul li.hasChildren a.nav-link {
		padding: 0.6rem 0rem 0.7rem 0.5rem;
	}

	.sticky .mega-desktop .accesible-navigation-menu li.hasChildren .container-wrapper ul {
		margin-top: -27px;
	}
}

@media (max-width: 991px) {
	.logo-link img {
		min-width: 151px;
	}
}

@media (max-width: 767px) {
	.logo-link {
		width: 200px;
	}
}

@media (max-width: 479px) {
	.logo-link {
		width: 230px;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
.mobile-navigation-menu {
	display: none;
	margin-top: 97px;
	height: calc(100% - 97px);
}

@media (min-width: 992px) {
	.mobile-navigation-menu,
	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu li[data-breakpoints="xl"] {
	display: flex !important;
}

.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
	display: flex !important;
}

.mobile-navigation-menu li[data-breakpoints="xs,sm,md,lg,xl"] {
	display: flex !important;
}

@media (max-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints="xl"] {
		display: none !important;
	}

	.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
		display: flex !important;
	}
}

@media (max-width: 479px) {
	.mobile-navigation-menu li[data-breakpoints="xl"] {
		display: none !important;
	}

	.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
		display: none !important;
	}
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mm-panels > .mm-panel,
.mm-navbar {
	background-color: #fff;
}

.mobile-navigation-menu li a.nav-link {
	text-transform: uppercase;
	align-items: center;
	padding: 1rem 20px;
	font-family: Outfit, sans-serif;
	font-size: 17px;
	font-weight: 500;
	line-height: 24px;
	transition: all 0.3s;
	color: #053061;
}

.mobile-navigation-menu li a.nav-link:hover,
.mobile-navigation-menu li a.nav-link.active {
	color: #67011f;
}

.mm-listitem:after {
	border-bottom: 0px;
}

.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
	border: 0px;
}

.mm-listitem_opened > .mm-listitem__btn,
.mm-listitem_opened > .mm-panel {
	background-color: transparent;
}

.mm-listitem_opened > .mm-listitem__btn,
.mm-listitem_opened > .mm-panel {
	padding-top: 0px;
}

.mm-wrapper_opened .mega-desktop {
	z-index: 100 !important;
}

.mm-wrapper_opened #page {
	z-index: 0 !important;
}

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu {
	width: calc(100% - 181px);
}

.accesible-navigation-menu ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	display: flex;
}

.accesible-navigation-menu ul.uui-navbar02_menu-left-2 {
	margin-right: 16px;
	margin-top: 1px;
}

.accesible-navigation-menu ul li a.nav-link {
	text-decoration: none;
	white-space: normal;
	text-align: center;
}

.accesible-navigation-menu ul li.hasChildren a.nav-link {
	padding: 1.75rem 0rem 1.75rem 0.5rem;
}

.accesible-navigation-menu ul li a.nav-link.active {
	color: #67011f;
}

.accesible-navigation-menu ul li.hasChildren a.nav-link:after {
	content: "\e603";
	font-family: webflow-icons !important;
	color: #053061;
	display: block;
	margin-left: 7px;
	font-size: 16px;
	transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	transform-style: preserve-3d;
	transition: all 400ms ease;
}

.accesible-navigation-menu li.hasChildren .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: auto;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
	width: 100%;
	padding-left: 0px;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul {
	display: none;
	background-color: #053061;
	padding: 0;
	margin: 0px;
	list-style-type: none;
	width: 100%;
	margin-top: -40px;
	min-width: 200px;
	padding-top: 0.5rem;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul li {
	display: flex;
	width: 100%;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul a {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: capitalize;
	margin-bottom: 0.25rem;
	font-family: Outfit, sans-serif;
	font-weight: 400;
	display: inline-block;
	width: 100%;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	word-wrap: break-word;
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	white-space: normal;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul a:hover,
.accesible-navigation-menu li.hasChildren .container-wrapper ul a.w--current {
	color: #5fa4ce;
	font-family: Outfit, sans-serif;
}

.accesible-navigation-menu li.hasChildren.open .container-wrapper,
.accesible-navigation-menu li.hasChildren:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.hasChildren.open .container-wrapper ul,
.accesible-navigation-menu li.hasChildren:hover .container-wrapper ul {
	display: inline-block;
}

@media (max-width: 1356px) {
	.uui-navbar02_dropdown-toggle-2 {
		padding: 0.7rem 0px 0.5rem 0px;
	}
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Homepage ******************/
/*************************************************/
.cbtext p {
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	color: inherit;
	font-weight: inherit;
}

.cbtext p:last-child {
	margin-bottom: 0px;
}

.cbtext.white p,
.cbtext.white li,
.cbtext.white a,
.cbtext.white a:hover,
.cbtext.white td,
.cbtext.white span,
.cbtext.white h1,
.cbtext.white h2,
.cbtext.white h3,
.cbtext.white h4,
.cbtext.white h5,
.cbtext.white h6 {
	color: #fff;
}

.heading-13.small {
	width: 55%;
}

/****************** Homepage ******************/
/*************************************************/
.slider-text p {
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	color: inherit;
	font-weight: inherit;
}

.slider-text p:last-child {
	margin-bottom: 0px;
}

.heading-13.small {
	min-width: 18vw;
}

@media screen and (max-width: 991px) {
	.button-with-arrow.desktop {
		display: none;
	}

	.content-wrap-stats-2 {
		margin-bottom: 60px;
	}

	.tab-pane-established-nationally,
	.tab-pane-growing-in-iowa {
		padding-top: 35px;
	}
}

@media screen and (max-width: 991px) {
	.heading-13.small {
		min-width: 27vw;
	}
}

@media screen and (max-width: 479px) {
	.heading-13.small {
		min-width: 40vw;
	}
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube.com"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumb-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list li:last-child a:hover,
.breadcrumb-list li:last-child a {
	text-decoration: none;
	color: #313131;
	letter-spacing: 0.02rem;
	text-transform: uppercase;
	font-size: 0.8rem;
	line-height: 1rem;
	border-bottom: 1px solid transparent;
}

.left-nav-list-link-2.active {
	color: #67011f;
	background-image: none;
	background-position: 0 0;
	background-size: auto;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.left-nav-nested-list-link-2.active {
	color: #67011f;
	background-image: url('/20181016082423/assets/images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 6px;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

@media (max-width: 991px) {
	.right-col {
		width: 100%;
		margin-top: 40px;
	}

	.inside-content-wrapper-2 {
		display: block;
	}
}

@media screen and (max-width: 767px) {
	.breadcrumb-list li:last-child a:hover,
	.breadcrumb-list li:last-child a {
		font-size: 0.6875rem;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-col td img {
	max-width: none;
}

.right-col .box {
	font-family: Outfit, sans-serif;
	border-top: 3px solid #053061;
	margin-bottom: 1.5rem;
	margin-left: 0;
	padding-top: 0.75rem;
	font-size: 14px;
	line-height: 20px;
	color: #333;
}

.right-col .box p {
	font-family: Outfit, sans-serif;
	font-size: 14px;
	line-height: 20px;
	color: #333;
}

.right-col .box h4,
.right-col .box h3 {
	color: #67011f;
	margin-top: 10px;
	font-size: 32px;
	line-height: 36px;
	font-weight: bold;
	margin-bottom: 10px;
}

.right-col .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-col .box .contentbox_item_image {
	width: 24px;
}

.right-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-col .box.tools td:first-child img {
	margin-right: 5px;
}

.right-col a {
	color: #313131;
	border-bottom: 1px solid #797676;
	font-weight: 400;
	transition-duration: 0.2s;
	text-decoration: none;
}

.right-col .box.tools a {
	border-bottom: 0px;
}

.right-col .content > a {
	border-bottom: 0px;
}

.right-col a:hover {
	color: #721926;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

@media screen and (min-width: 992px) {
	.right-col {
		min-width: 146px;
	}
}

@media screen and (max-width: 991px) {
	.right-col .box {
		margin-left: 0;
	}

	.right-col .box h4,
	.right-col .box h3 {
		font-size: 28px;
	}
}

/************* Inside Page Style ******************/
/*************************************************/
h1,
h2,
h3,
h4 {
	color: #67011f;
}

h5 {
	color: #0a0442;
}

h1 a,
h2 a,
h3 a {
	color: #67011f !important;
}

h4 a,
h6 a,
h5 a {
	color: #313131 !important;
}

h4 a:hover,
h6 a:hover,
h5 a:hover {
	color: #67011f !important;
}

.inside-page-content-wrap-no-sidebars-2,
p {
	font-size: 1.125rem;
}

@media screen and (max-width: 991px) {
	h1 {
		font-size: 30px;
		line-height: 34px;
	}

	h2 {
		font-size: 28px;
	}
}

@media screen and (max-width: 479px) {
	h1 {
		margin-top: 10px;
	}
}

.inside-content-column a,
.left-sidebar a {
	color: #313131;
	border-bottom: 0 solid #797676;
}

.inside-content-column a:hover,
.left-sidebar a:hover {
	color: #67011f;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	font-size: 0.875rem;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	background-color: rgba(141, 198, 65, 0.1);
	border-left-color: #053061;
}

blockquote p {
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}


/* messages */
#message.success {
	color: #000;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
	font-weight: 400;
}

#message.error {
	color: #000;
	background-color: #ffccd0;
	border: 2px solid #721926;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
	font-weight: 400;
}

#message.success p {
	color: #000;
	font-size: 1.125rem;
}

#message.error p {
	color: #000;
	font-size: 1.125rem;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: 400;
	text-transform: none;
	letter-spacing: 0.02em;
	color: white;
	background-color: #053061;
	border-bottom: 1px solid white;
	padding: 0.875em 0.625rem;
	text-align: left;
	font-size: 1.125rem;
}

table.styled th {
	font-weight: 300;
	padding: 0.625em 0.625rem;
	background-color: #166b3e;
	border-bottom: 0px solid white;
	color: white;
	text-align: left;
	font-size: 1.125rem;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 16.8px;
	line-height: 1.4125em;
}

table.styled.striped tr:nth-child(even) {
	background: #eee;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.875em;
	line-height: 1.25em;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	border-bottom: 1px solid transparent;
	font-style: normal;
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	border: 0;
	cursor: pointer;
	border-radius: 0px;
	min-width: 100px;
	color: #fff !important;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	background-color: #053061 !important;
	padding: 7px 1.15rem 8px;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.3s;
}

.inside-row .button:hover,
.inside-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	color: #fff !important;
	background-color: #166b3e !important;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	border: 0;
	cursor: pointer;
	border-radius: 0px;
	min-width: 100px;
	color: #166b3e !important;
	text-align: center;
	letter-spacing: 0rem;
	text-transform: uppercase;
	background-color: transparent !important;
	padding: 0;
	font-size: 1rem;
	font-family: Outfit, sans-serif;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.3s;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	background-color: transparent !important;
	color: #67011f !important;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	border: 0;
	cursor: pointer;
	border-radius: 0px;
	min-width: 100px;
	color: #fff !important;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	background-color: #67011f !important;
	padding: 7px 1.15rem 8px;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.3s;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	background-color: #07693a !important;
	color: #fff !important;
}

/***************** Footer ***********************/
/*************************************************/
#GRIPFooterLogo {
	padding-top: 0px !important;
	line-height: 11px;
}

.phone-desktop {
	display: inline-block;
}

.phone-mobile {
	display: none;
}

.footer-links-list {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.footer-links-list li a {
	display: inline-block;
}

@media screen and (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}
}

@media screen and (max-width: 479px) {
	#GRIPFooterLogo {
		margin-bottom: 10px;
	}
}

/****************** Content Modules ****************/
/***************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-element-with-example .source-code {
	overflow: hidden;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .cms_buttons {
	margin-left: calc(33% + 10px);
}

#submissionforms_module.cms_form .sigPad input {
	border-color: #666;
}

/* faqs */
#faqs_module h2 {
	font-size: 35px;
	line-height: 38px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 5px;
	font-size: 1rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: rgba(92, 92, 92, 0.15);
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: bold;
}

#jobs_module.cms_entity h2 {
	font-size: 35px;
	margin-top: 20px;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.75rem 3.75rem 0.75rem 1.25rem;
	margin-top: 10px;
	margin-left: 0px;
	margin-right: 0px;
}

@media (min-width: 992px) {
	#jobs_module.cms_form #frmJobSubscription p {
		width: 65%;
		text-align: left;
		float: right;
		padding-left: 5px;
	}
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* locations*/
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(92, 92, 92, 0.15);
}

#locations_module.list .type_item .title,
#locations_module.list .type_item .title a {
	font-size: 1.5rem;
}

@media (min-width: 992px) {
	#locations_module_search_contentbox.contentbox_item .item_field input#PostalCode {
		width: calc(40% - 10px);
	}

	#locations_module_search_contentbox.contentbox_item .item_field select#MilesRadius {
		width: 58%;
	}
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* resources */

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/****************** Content Boxes ******************/
/***************************************************/
/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}


/* news */
#news_module.homepage_contentbox {
	text-align: left;
}

#news_module.homepage_contentbox h3 {
	margin: 0px;
	margin-top: 5px;
}

#news_module.homepage_contentbox .cms_title h3 a {
	font-size: 1.2rem;
	margin: 0px;
	line-height: 1.6rem;
}

#news_module.homepage_contentbox .cms_date h3 {
	font-size: 1rem;
	line-height: 1.4rem;
	margin-top: 5px;
}

#news_module.homepage_contentbox a {
	color: #333;
}
